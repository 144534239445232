import {useLazyQuery} from '@apollo/client';
import DocumentCard from '@app/components/Common/Block/Document/DocumentBlock/DocumentCard';
import FlocDocumentSelectionModal from '@app/components/Common/Block/Document/DocumentBlock/FlocDocumentSelectionModal';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {Document, FunctionalLocation, GetDocumentsByFlocIdsQuery} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS} from '@app/graphql/requests';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {EAppFlagStatus} from '@app/utils/enums';
import {searchArray} from '@app/utils/functions';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuFile, LuPlusSquare} from 'react-icons/lu';

type TCmlList = Readonly<{
  floc: Partial<FunctionalLocation>;
  readonly?: boolean;
}>;

/** Floc document list */
export default function DocumentList({floc, readonly}: TCmlList) {
  const [filteredDocuments, setFilteredDocuments] = useState<Partial<Document>[]>();
  const [filterString, setSearchInput] = useState<string>('');
  const {t} = useTranslation();
  const {
    documents,
    setDocuments,
    fetchDocuments,
    setFetchDocuments,
    documentSelectionModalDisplayed,
    changeDocumentSelectionModalDisplay,
  } = useFlocStore();

  const [getDocumentListApi] = useLazyQuery<GetDocumentsByFlocIdsQuery>(
    DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS,
  );

  useEffect(() => {
    setFetchDocuments(getDocuments);
  }, [floc.id]);

  useEffect(() => {
    fetchDocuments?.();
  }, [fetchDocuments]);

  useEffect(() => {
    setFilteredDocuments(
      searchArray(
        documents ?? [],
        filterString,
        undefined,
        ['__typename'],
      ).filter((document: Partial<Document>) => (
        document.flagStatus === EAppFlagStatus.ACTIVE.toString()
      )),
    );
  }, [documents, filterString]);

  const getDocuments = () => {
    getDocumentListApi({
      variables: {
        flocIds: [floc.id],
        orderBy: [
          {
            id: 'desc',
          },
        ],
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResults => setDocuments(queryResults.data?.documents as Document[] ?? []));
  };

  return (
    <>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key='manage-docs' disabled={readonly} onClick={() => changeDocumentSelectionModalDisplay(true)}><LuPlusSquare className='mr-2'/> {t('label.manageDocumentation')}</RadDropdownMenuItem>,
        ]}
        actions={
          <SearchBar
            className='w-[300px]'
            onChange={e => setSearchInput(e.target?.value)}
          />
        }
      >
        <div className='flex items-center'>
          <LuFile size={20} className='mr-2'/>

          {t('label.documentation')}
        </div>
      </FormGroupHeader>

      <div className='flex flex-col gap-1 mt-3'>
        {filteredDocuments?.map(document => (
          <DocumentCard
            key={document.id}
            hasContextMenu
            doc={document}
            readonly={readonly}
          />
        ))}
      </div>
      {documentSelectionModalDisplayed && <FlocDocumentSelectionModal/>}
    </>
  );
}
