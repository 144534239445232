import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {Notification} from '@app/graphql/__types__/graphql';
import {RadButton} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';
import {LuClipboardList, LuPlusSquare} from 'react-icons/lu';
import IanCard from '../../../Ian/IanCard';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import React from 'react';

type TNotificationList = Readonly<{
  notifications?: Partial<Notification>[];
  createIan?: () => void;
  createIanBtnDisabled?: boolean;
  isLoading?: boolean;
  error?: React.ReactNode;
}>;

export default function NotificationList({notifications, createIanBtnDisabled, createIan, isLoading, error}: TNotificationList) {
  const {t} = useTranslation();
  return (
    <div className='flex flex-col gap-2 mt-2'>
      <FormGroupHeader
      >
        <div className='flex gap-4 items-center'>
          <div className='flex items-center'>
            <LuClipboardList className='mr-2' size={20}/> {t('label.IAN')} ({notifications?.length ?? 0})
          </div>
          {!!createIan && <RadButton variant='outline' disabled={createIanBtnDisabled} className='text-foreground' onClick={createIan}><LuPlusSquare className='mr-2'/> {t('label.addIAN')}</RadButton>}
        </div>
      </FormGroupHeader>
      <SpinnerLoaderComponent isLoading={isLoading} error={error}>
        <div className='grid grid-cols-2 gap-2'>
          {
            notifications?.map(notif => (
              <IanCard key={`ian-${notif.id}`} notification={notif}/>
            ))
          }
        </div>
      </SpinnerLoaderComponent>
    </div>
  );
}
