import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer';
import {Event, FunctionalLocation, InspectionDrawing, Notification} from '@app/graphql/__types__/graphql';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DrawingPageHeader from './Header';

type TDrawingViewers = Readonly<{
  drawings?: Partial<InspectionDrawing>[];
  selectedDrawing?: Partial<InspectionDrawing>;
  setSelectedDrawing: (drawing?: Partial<InspectionDrawing>) => void;
  visible: boolean;
  objectItem?: Partial<FunctionalLocation|Event|Notification>;
  hideTechniquesBlock?: boolean;
  hideCreateDamage?: boolean;
}>;

export default function DrawingViewers({hideCreateDamage, drawings, visible, selectedDrawing, setSelectedDrawing, objectItem, hideTechniquesBlock}: TDrawingViewers) {
  const {t} = useTranslation();

  useEffect(() => {
    if (drawings?.length === 1) {
      setSelectedDrawing(drawings[0]);
    } else {
      setSelectedDrawing();
    }
  }, [drawings]);

  return (
    <div className={`h-full overflow-hidden flex flex-col ${!visible ? 'hidden' : ''}`}>
      <DrawingPageHeader nbDrawings={drawings?.length} selectedDrawing={selectedDrawing} goBack={setSelectedDrawing} hideActions={objectItem?.__typename === 'Notification'}/>

      <div className={selectedDrawing || !drawings?.length ? 'h-full w-full' : 'grid grid-cols-2 w-full gap-4 overflow-auto'}>
        {drawings?.length ? drawings.map((item: Partial<InspectionDrawing>) => (<DrawingViewer key={`drawing-viewer-${item.id!}`} hideTechniquesBlock={hideTechniquesBlock} hideCreateDamage={hideCreateDamage} hasActionList={false} objectItem={objectItem} idwg={item}/>)) : (typeof drawings === 'undefined' ? t('label.loading') : t('label.noDrawingsFound'))}
      </div>
    </div>
  );
}
