import DocumentationBlock from '@app/components/Common/Block/Event/EventModal/components/DocumentationBlock';
import {RightPanelPage} from '../..';
import {useEventStore} from '@app/stores/event';
import {Doc, EventDocument, GetDocByNameQuery} from '@app/graphql/__types__/graphql';
import {useEffect, useState} from 'react';
import {createObjUrlFromUrl} from '@app/utils/functions';
import ImgViewer from '@app/components/Common/Viewer/ImgViewer';
import PDFViewer from '@app/components/Common/Viewer/PDFViewer';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import moment from 'moment';
import {useHolisAuth} from '@holis/auth-client-react';
import {useLazyQuery} from '@apollo/client';
import {FILE_DOCS_GET_BY_NAME} from '@app/graphql/requests';
import DocumentationPageDocHeader from './DocHeader';

export default function DocumentationPage({visible, readonly}: RightPanelPage) {
  const [selectedDoc, setSelectedDoc] = useState<Partial<EventDocument>>();
  const [selectedFileDoc, setSelectedFileDoc] = useState<Partial<Doc>>();
  const [docSrc, setDocSrc] = useState<string>();
  const [docIsPdf, setDocIsPdf] = useState<boolean>(false);
  const {activeEvent} = useEventStore();
  const {getAccessToken} = useHolisAuth();
  const [getFileDocByDocNameApi] = useLazyQuery<GetDocByNameQuery>(FILE_DOCS_GET_BY_NAME);

  const getFileDoc = () => {
    getFileDocByDocNameApi({
      variables: {
        docName: selectedDoc?.document?.docName,
      },
      fetchPolicy: 'no-cache',
    })
      .then(result => setSelectedFileDoc(result.data?.doc as Partial<Doc> | undefined))
      .catch(() => {
        setDocSrc(undefined);
        setSelectedFileDoc(undefined);
      });
  };

  useEffect(() => {
    if (selectedDoc?.document?.docName) {
      getFileDoc();
    }
  }, [selectedDoc]);

  useEffect(() => {
    createObjUrlFromUrl(
      `${OptimusClientConfig.current.fileBaseUrl}/${selectedFileDoc?.docName}?ts=${moment().toISOString()}`,
      {getAccessToken})
      .then(url => {
        setDocSrc(url ?? undefined);
        setDocIsPdf(selectedFileDoc?.extension?.toLowerCase() === 'pdf');
      });
  }, [selectedFileDoc]);

  const render = () => {
    if (!activeEvent) {
      return null;
    }

    if (docSrc && selectedDoc) {
      return (
        <div className='h-full w-full flex flex-col'>
          <DocumentationPageDocHeader
            selectedDocument={selectedDoc}
            goBack={() => {
              setSelectedDoc(undefined);
              setSelectedFileDoc(undefined);
              setDocSrc(undefined);
            }}/>
          {docIsPdf ? <PDFViewer
            isExternalSrc
            file={docSrc}
          /> : <ImgViewer
            isExternalSrc
            src={docSrc}/>}
        </div>
      );
    }

    return <DocumentationBlock event={activeEvent} readonly={readonly} docSelected={setSelectedDoc}/>;
  };

  return (
    <div className={(visible ? '' : 'hidden') + ' h-full'}>
      {render()}
    </div>
  );
}
