import React, {useState} from 'react';
import useNotificationStore from '@app/stores/notification';
import {Notification, UpdateNotificationByIdMutation} from '@app/graphql/__types__/graphql';
import {useMutation} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {NOTIFICATIONS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import {useHolisAuth} from '@holis/auth-client-react';
import {t} from 'i18next';
import {LuClipboardList} from 'react-icons/lu';
import ReactQuill from 'react-quill';

type TLongDescriptionBlock = Readonly<{
  notification: Partial<Notification>
  readonly?: boolean
}>

export default function LongDescriptionBlock({notification, readonly}: TLongDescriptionBlock) {
  const {updateNotification, editNotification, activeNotification, createNotificationWorkflowFunc} = useNotificationStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [updateNotifById] = useMutation<UpdateNotificationByIdMutation>(NOTIFICATIONS_UPDATE_BY_ID);
  const [longDescription, setLongDescription] = useState<string>(notification.longDescription ?? '');
  const {user} = useHolisAuth();

  const handleUpdateNotificationReportSummary = () => {
    const actionDate = new Date();
    if (longDescription !== activeNotification?.longDescription) {
      startLoading();
      updateNotifById({variables: {id: activeNotification?.id!, data: {longDescription: {set: longDescription}}}}).then(newData => {
        const newNotification = {
          ...newData?.data?.updateOneNotification,
        } as Partial<Notification>;
        if (newNotification.status !== editNotification?.status) {
          createNotificationWorkflowFunc?.({
            variables: {
              data: {
                userLogin: user?.username,
                actionDate,
                status: newNotification.status,
                wrkoId: activeNotification?.id!,
              },
            },
          });
        }

        updateNotification(newNotification);

        AppNotifications.success(t('message.success.notificationUpdated'));
        return true;
      }).catch((_err: Error) => {
        const errorMessage: string = t('message.error.default.title');

        AppNotifications.error(errorMessage);
        return false;
      }).finally(() => {
        stopLoading();
      });
    }
  };

  return (
    <div className='flex flex-col'>
      <FormGroupHeader className='mb-3'><LuClipboardList className='mr-2' size={20}/> {t('label.ianSummary')}</FormGroupHeader>

      <div className='h-[330px]'>
        <ReactQuill key={editNotification!.longDescription} readOnly={readonly} className='mr-2 h-[290px]' theme='snow' defaultValue={longDescription ?? ''} onChange={setLongDescription} onBlur={handleUpdateNotificationReportSummary}/>
      </div>
    </div>
  );
}
