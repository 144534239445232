import {GetInspectionDrawingsByFlocIdQuery, InspectionDrawing, Notification} from '@app/graphql/__types__/graphql';
import useNotificationStore from '@app/stores/notification';
import {EIanRightSideTab} from '@app/utils/enums';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLazyQuery} from '@apollo/client';
import {INSPECTION_DRAWINGS_GET_BY_FLOC_ID} from '@app/graphql/requests';
import {useLayoutStore} from '@app/stores/layout';
import LongDescriptionBlock from './LongDescription';
import NotificationAttachments from './Attachments';
import DrawingViewers from '@app/components/Common/Block/InspectionDrawing/DrawingViewers';
import {RadTabs, RadTabsList, RadTabsTrigger, RadBadge} from '@holis/react-ui/rad';
type TRightSide = Readonly<{
  notification: Partial<Notification>;
  readonly?: boolean;
}>;

export default function RightSide({notification, readonly}: TRightSide) {
  const {t} = useTranslation();
  const {editNotification, setNotificationFlocDrawings, setFetchObjectDrawings, rightSideTabSelectedValue, changeRightSideTab, selectedDrawing, setSelectedDrawing, notificationDrawings, setDamageDrawingIds, notificationDamages, notificationFlocDrawings, setNotificationDrawings, damageDrawingIds} = useNotificationStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [getDrawingsByFlocIdApi] = useLazyQuery<GetInspectionDrawingsByFlocIdQuery>(INSPECTION_DRAWINGS_GET_BY_FLOC_ID, {
    fetchPolicy: 'no-cache',
  });
  const flocId = useMemo(() => editNotification?.flocId, [editNotification]);

  const getDrawings = () => {
    if (flocId) {
      startLoading();
      getDrawingsByFlocIdApi({
        variables: {
          flocId,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setNotificationFlocDrawings((queryResult.data?.inspectionDrawings ?? []) as Partial<InspectionDrawing>[]);
      }).finally(() => {
        stopLoading();
      });
    }
  };

  useEffect(() => {
    if (flocId) {
      getDrawings();
      setFetchObjectDrawings?.(getDrawings);
    }
  }, [flocId]);

  useEffect(() => {
    setDamageDrawingIds(notificationDamages?.map(notifDamage => notifDamage.damage?.idwgId!));
  }, [notificationDamages]);

  useEffect(() => {
    setNotificationDrawings(notificationFlocDrawings?.filter(notifFlocDrawing => !!damageDrawingIds?.includes(notifFlocDrawing.id!)));
  }, [notificationFlocDrawings, damageDrawingIds]);

  const tabs = [
    {label: t('label.drawing'), value: EIanRightSideTab.DRAWINGS, counter: notificationDrawings?.length},
    {label: t('label.longDescription'), value: EIanRightSideTab.LONG_DESCRIPTION},
  ];

  return (
    <div className='h-full w-full flex flex-col'>
      <RadTabs className='pt-2 px-4' value={rightSideTabSelectedValue}>
        <RadTabsList>
          { tabs.map(tab => (
            <RadTabsTrigger key={tab.label} value={tab.value} onClick={() => changeRightSideTab(tab.value)}>
              {tab.label}
              {tab.counter !== undefined && <RadBadge className='rounded-full ml-1 text-2xs p-1 h-4 min-w-4 justify-center'>{tab.counter}</RadBadge>}
            </RadTabsTrigger>
          )) }
        </RadTabsList>
      </RadTabs>
      <div className='p-4 flex-1 flex flex-col overflow-auto'>
        <DrawingViewers hideTechniquesBlock hideCreateDamage visible={rightSideTabSelectedValue === EIanRightSideTab.DRAWINGS} objectItem={notification} drawings={notificationDrawings} setSelectedDrawing={setSelectedDrawing} selectedDrawing={selectedDrawing}/>
        <div className={`h-full w-full ${rightSideTabSelectedValue === EIanRightSideTab.LONG_DESCRIPTION ? 'flex flex-col gap-2' : 'hidden'}`}>
          <LongDescriptionBlock notification={notification} readonly={readonly}/>
          <NotificationAttachments notification={notification} readonly={readonly}/>
        </div>
      </div>
    </div>
  );
}
