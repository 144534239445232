import {useTranslation} from 'react-i18next';
import DrawingPage from './pages/Drawing';
import PicturePage from './pages/Picture';
import {useEventStore} from '@app/stores/event';
import ReportPage from './pages/Report';
import {EWorkpackRightSideTab} from '@app/utils/enums';
import FlocOverviewDrawing from '@app/components/Common/Block/Floc/FlocBlock/FlocOverviewDrawing';
import EventPage from './pages/Event';
import DocumentationPage from './pages/Documentation';
import {RadBadge, RadTabs, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';

export type RightPanelPage = Readonly<{
  visible?: boolean;
  readonly?: boolean;
}>;

export type RightPanelProps = Readonly<{
  readonly?: boolean;
}>;

export default function RightPanel({readonly}: RightPanelProps) {
  const {t} = useTranslation();
  const {pictures, activeEvent, setRightSideTabSelectedValue, rightSideTabSelectedValue, eventInspectionDrawings, eventDocuments} = useEventStore();

  const tabs = [
    {label: t('label.event'), value: EWorkpackRightSideTab.EVENT},
    {label: t('label.picture'), value: EWorkpackRightSideTab.PICTURES, counter: pictures?.length},
    {label: t('label.drawing'), value: EWorkpackRightSideTab.DRAWINGS, counter: eventInspectionDrawings?.length},
    {label: t('label.overview'), value: EWorkpackRightSideTab.OVERVIEW},
    {label: t('label.documentation'), value: EWorkpackRightSideTab.DOCUMENTATION, counter: eventDocuments?.length},
  ];

  return (
    <div className='h-full w-full'>
      <div className='h-full w-full flex flex-col'>

        <div className=''>
          <RadTabs className='pt-2 px-4' value={rightSideTabSelectedValue}>
            <RadTabsList>
              { tabs.map(tab => (
                <RadTabsTrigger key={tab.label} value={tab.value} onClick={() => setRightSideTabSelectedValue(tab.value)}>
                  {tab.label}
                  {tab.counter !== undefined && <RadBadge className='rounded-full ml-1 text-2xs p-1 h-4 min-w-4 justify-center'>{tab.counter}</RadBadge>}
                </RadTabsTrigger>
              )) }
            </RadTabsList>
          </RadTabs>
        </div>
        <div className={`pt-4 px-4 ${rightSideTabSelectedValue === EWorkpackRightSideTab.OVERVIEW ? 'pb-0' : 'pb-4'} flex-1 flex flex-col overflow-auto`}>

          <EventPage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.EVENT} readonly={readonly}/>

          <PicturePage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.PICTURES} readonly={readonly}/>

          <DrawingPage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS} readonly={readonly}/>

          {activeEvent?.schedulingTag && <FlocOverviewDrawing floc={activeEvent.schedulingTag} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.OVERVIEW}/>}

          <DocumentationPage readonly={readonly} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.DOCUMENTATION}/>

          <ReportPage readonly={readonly} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.REPORT}/>

        </div>
      </div>
    </div>
  );
}
