import {TGridProps} from '@app/components/Common/Grid';
import _ from 'lodash';

export function ensureDefaultGridLayout(gridName: string, defaultLayoutString: string) {
  const savedLayout = localStorage.getItem(`TREELIST_${gridName}`);
  if (savedLayout) {
    const layoutState = JSON.parse(savedLayout);
    if (layoutState?.state && Object.keys(layoutState.state).length > 0) {
      return;
    }
  }

  localStorage.setItem(`TREELIST_${gridName}`, defaultLayoutString);
}

export function mergeDefaultLayout(gridProps: TGridProps, defaultLayout: string) {
  const mergedColumns = [];

  const defaultLayoutState = JSON.parse(defaultLayout);
  const defaultColumns = Object.entries(defaultLayoutState.state?.treeState?.columns).map(([field, col]) => ({...(col as object), field}));
  if (!defaultColumns) {
    return;
  }

  for (const col of defaultColumns) {
    const foundColIndex = gridProps.columns?.findIndex(c => c.field === col.field);
    if (foundColIndex === null || foundColIndex === -1) {
      continue;
    }

    const foundCol = gridProps.columns?.[foundColIndex!];
    const mergedCol = _.merge(foundCol, col);
    mergedColumns.push(mergedCol);
    gridProps.columns?.splice(foundColIndex!, 1);
  }

  for (const col of gridProps.columns ?? []) {
    mergedColumns.push({...col, hidden: true});
  }

  gridProps.columns = mergedColumns;
}
