import {useLazyQuery, useMutation} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {Event, EventDocument, Doc, DeleteOneEventDocumentByIdMutation, CreateAttachmentMutation, GetDocWithContentByNameQuery, Attachment} from '@app/graphql/__types__/graphql';
import {FILE_ATTACHMENTS_CREATE, FILE_DOCS_GET_WITH_CONTENT_BY_NAME, WORKORDER_EVENT_DOCUMENTS_DELETE_ONE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useEventStore} from '@app/stores/event';
import {useLayoutStore} from '@app/stores/layout';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
import {t} from 'i18next';
import {useEffect} from 'react';
import {LuFile, LuPlusSquare} from 'react-icons/lu';
import {EImportService} from '@app/utils/enums';
import {useDataStore} from '@app/stores/data';
import DocumentCard from '@app/components/Common/Block/Document/DocumentBlock/DocumentCard';
import {createBlobFromUrl} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import {OptimusClientConfig} from '@app/utils/clientConfig';

export type DocumentationBlockProps = Readonly<{
  readonly?: boolean;
  event: Partial<Event>;
}>;

export default function DocumentationBlock({readonly, event}: DocumentationBlockProps) {
  const {eventDocuments, fetchEventDocuments, fetchEventAttachments, activeEvent, changeDocumentSelectionModalDisplay} = useEventStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const config = useOptimusConfig();
  const {getAccessToken} = useHolisAuth();
  const [deleteEventDocumentApi] = useMutation<DeleteOneEventDocumentByIdMutation>(WORKORDER_EVENT_DOCUMENTS_DELETE_ONE_BY_ID);
  const [createAttachmentApi] = useMutation<CreateAttachmentMutation>(FILE_ATTACHMENTS_CREATE);
  const [getDocWithContentApi] = useLazyQuery<GetDocWithContentByNameQuery>(FILE_DOCS_GET_WITH_CONTENT_BY_NAME);
  const {uploadDownloadService} = useDataStore();

  const uploadDocToAttachment = (file: File, attachment: Attachment) => uploadDownloadService!.uploadFile(file, {id: attachment.id}, EImportService.UPLOAD_ATTACHMENT).catch((err: Error) => {
    console.log(err);
    AppNotifications.error(t('message.error.importService.attachmentUploadFailed'));
  }).finally(() => {
    stopLoading();
  });

  const addToAttachments = async (evtDoc: Partial<EventDocument>) => {
    startLoading();
    let doc: Doc | null = null;
    let file: File | null = null;
    if (evtDoc.document!.docName) {
      const queryResult = await getDocWithContentApi({
        variables: {
          docName: evtDoc.document!.docName,
        },
      });
      doc = queryResult.data?.doc as Doc;
      const dataBlob = await createBlobFromUrl(`${OptimusClientConfig.current.fileBaseUrl}/${evtDoc.document!.docName}`, {getAccessToken, onFail() {
        AppNotifications.error(t('message.error.default.title'));
        return null;
      }}).catch(() => null);
      if (dataBlob) {
        file = new File([dataBlob], doc.docName);
      }
    }

    createAttachmentApi({
      variables: {
        data: {
          wrkoId: activeEvent?.id,
          attachmentName: evtDoc.document?.docName?.substring(0, Math.min(50, evtDoc.document?.docName?.length ?? 0)),
          description: evtDoc.document?.description?.substring(0, Math.min(50, evtDoc.document?.description.length ?? 0)),
          contentType: doc?.contentType,
          extension: doc?.extension,
        },
      },
    }).then(async queryResult => {
      const attachment = queryResult.data?.createOneAttachment as Attachment;
      if (file && attachment) {
        await uploadDocToAttachment(file, attachment);
      }

      fetchEventAttachments?.();
      AppNotifications.success(t('message.success.addAttachment'));
    }).finally(() => {
      stopLoading();
    });
  };

  const removeFromEvent = (evtDoc: Partial<EventDocument>) => {
    startLoading();
    deleteEventDocumentApi({
      variables: {
        id: evtDoc.id,
      },
    }).then(() => {
      AppNotifications.success(t('message.success.deleteEventDocument'));
      fetchEventDocuments?.();
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  useEffect(() => {
    fetchEventDocuments?.();
  }, [fetchEventDocuments]);

  return (
    <>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key='manage-docs' disabled={readonly || config.getActionIsDisabled('event', 'docSelect', event.status)} onClick={() => changeDocumentSelectionModalDisplay(true)}><LuPlusSquare className='mr-2'/> {t('label.manageDocumentation')}</RadDropdownMenuItem>,
        ]}
      >
        <div className='flex items-center'>
          <LuFile size={20} className='mr-2'/> {t('label.documentation')}
        </div>
      </FormGroupHeader>

      <div className='flex flex-col gap-1 mt-3'>
        {
          eventDocuments?.map(evtDoc => (
            <DocumentCard key={evtDoc.id} hasContextMenu doc={evtDoc.document!} eventDocument={evtDoc} event={activeEvent} addToAttachments={() => addToAttachments(evtDoc)} removeFromEvent={() => removeFromEvent(evtDoc)}/>
          ))

        }
      </div>
    </>
  );
}
