import {RadBadge} from '@holis/react-ui/rad';
import React, {HTMLProps} from 'react';

export type TPageItem = Readonly<{
	label: string
	value?: string
  counterValue?: number
}>;

export type TSimplePageSelector = Readonly<{
	items: TPageItem[]
	selectedValue?: string
	onSelectedValueChanged?: (value: string) => void
}> & HTMLProps<HTMLDivElement>;

export default function SimplePageSelector({items, selectedValue, onSelectedValueChanged, ...divProps}: TSimplePageSelector) {
  const baseClassName = 'cursor-pointer';
  const selectedItemClassName = `${baseClassName} text-primary`;
  const normalItemClassName = `${baseClassName} text-gray-500 hover:text-black`;

  return (
    <div {...divProps}>
      <div className='flex w-full justify-center items-center text-sm flex-wrap'>
        {
          items.map((item, i) => {
            const val = item.value ?? item.label;
            return (
              <React.Fragment key={`item-${val}`}>
                <div
                  className={selectedValue === val ? selectedItemClassName : normalItemClassName}
                  onClick={() => {
                    onSelectedValueChanged?.(val);
                  }}>
                  <div className='flex items-center mb-1'>
                    <div className='whitespace-nowrap'>
                      {item.label}
                    </div>
                    {
                      item.counterValue !== undefined && (
                        <RadBadge className='rounded-full ml-1 text-2xs p-1 h-4 min-w-4 justify-center'>{item.counterValue}</RadBadge>
                      )
                    }
                  </div>
                </div>
                {i < items.length - 1 && <div className='mx-2 text-gray-300'>|</div>}
              </React.Fragment>
            );
          })
        }
      </div>
    </div>
  );
}
