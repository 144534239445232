import {gql} from '@apollo/client';
import {DAMAGE_REF_VAL_CODES_COMMON_FIELDS, DAMAGES_COMMON_FIELDS, NOTIFICATION_DAMAGES_COMMON_FIELDS} from '../fragments';

export const NOTIFICATION_DAMAGES_CREATE = gql`
${NOTIFICATION_DAMAGES_COMMON_FIELDS}
mutation createNotificationDamage($dmgId: Int!, $notifId: Int!) {
  createOneNotificationDamage (data: {dmgeId: $dmgId, notifId: $notifId}) {
    ...NotificationDamageCommonFields
    notification {
      id
      notif
    }
  }
}
`;

export const NOTIFICATION_DAMAGES_CREATE_MANY = gql`
mutation createNotificationDamages($data: [NotificationDamageCreateManyInput!]!) {
  createManyNotificationDamage (data: $data) {
    count
  }
}
`;

export const NOTIFICATION_DAMAGES_DELETE = gql`
mutation deleteNotificationDamage($dmgId: Int!, $notifId: Int!) {
  deleteOneNotificationDamage (where: {dmgeId_notifId: {dmgeId: $dmgId, notifId: $notifId}}) {
    notifId
  }
}`;

export const NOTIFICATION_DAMAGES_GET_BY_NOTIF_ID = gql`
${NOTIFICATION_DAMAGES_COMMON_FIELDS}
${DAMAGES_COMMON_FIELDS}
${DAMAGE_REF_VAL_CODES_COMMON_FIELDS}
query GetNotificationDamagesByNotifId($notifId: Int!, $orderBy: [NotificationDamageOrderByWithRelationInput!]) {
  notificationDamages (where: {notifId: {equals: $notifId}}, orderBy: $orderBy) {
    ...NotificationDamageCommonFields,
    damage{
      ...DamageCommonFields
    }
    qualReading{
      ...RefDamageValCodeCommonFields
    }
  }
}`;

export const NOTIFICATION_DAMAGES_GET_COUNTER = gql`
query GetNotificationDamagesCounter {
  aggregateNotificationDamage {
    _count {
      _all
    }
  }
}
`;

export const NOTIFICATION_DAMAGES_DELETE_MANY = gql`
mutation deleteNotificationDamages($dmgIds: [Int!], $notifId: Int!) {
  deleteManyNotificationDamage (where: {notifId: {equals: $notifId}, dmgeId: {in: $dmgIds}}) {
    count
  }
}`;

export const NOTIFICATION_DAMAGES_UPDATE = gql`
${NOTIFICATION_DAMAGES_COMMON_FIELDS}
mutation updateNotificationDamage($data: NotificationDamageUncheckedUpdateInput!, $dmgId: Int!, $notifId: Int!) {
  updateOneNotificationDamage (data: $data, where: {dmgeId_notifId: {dmgeId: $dmgId, notifId: $notifId}}) {
    ...NotificationDamageCommonFields
  }
}`;
