import {useLazyQuery} from '@apollo/client';
import DamageModal from '@app/components/Common/Block/Damage/DamageModal';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import {Damage, DamageView, GetAllDamageViewsQuery, GetDamageDetailByIdQuery} from '@app/graphql/__types__/graphql';
import {DAMAGE_VIEWS_GET_ALL, DAMAGES_GET_DETAIL_BY_ID} from '@app/graphql/requests';
import {useDamagesStore} from '@app/stores/anomalyManagement/damage';
import useDamageStore from '@app/stores/damage';
import {useDataStore} from '@app/stores/data';
import {FORMAT_DATE_EU, GRID_DEFAULT_LAYOUT_DMG} from '@app/utils/constants';
import {ensureDefaultGridLayout, mergeDefaultLayout} from '@app/utils/functions/layout';
import {RadButton} from '@holis/react-ui/rad';
import moment from 'moment';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MdOpenInNew} from 'react-icons/md';

const GRID_NAME = 'anomalyManagement-damageGrid';

export default function AnomalyManagementDamagePage() {
  const {setDamages, setFetchDamages, damages, fetchDamages} = useDamagesStore();
  const {setActiveDamage, activeDamage} = useDamageStore();
  const {selectedSites} = useDataStore();
  const {t} = useTranslation();
  const [getDamagesApi, {loading, error}] = useLazyQuery<GetAllDamageViewsQuery>(DAMAGE_VIEWS_GET_ALL);
  const [getDamageByIdApi] = useLazyQuery<GetDamageDetailByIdQuery>(DAMAGES_GET_DETAIL_BY_ID);

  const getDamages = () => {
    if (selectedSites?.length) {
      getDamagesApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setDamages(queryResults.data?.damageViews as Partial<Damage>[]));
    } else {
      setDamages([]);
    }
  };

  const onOpenDamageClick = (id: number) => {
    getDamageByIdApi({
      variables: {
        id,
      },
    }).then(res => {
      setActiveDamage(res.data?.damage as Partial<Damage>);
    });
  };

  useEffect(() => {
    getDamages();
    setFetchDamages(getDamages);
  }, [selectedSites]);

  const gridProps: TGridProps = {
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 60,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
      },
      {
        field: 'floc_floc',
        title: t('label.functionalLocation'),
        type: 'string',
        width: 100,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 80,
        ignoreCase: true,
      },
      {
        field: 'floc_techClass_class_class',
        title: t('label.technicalClass'),
        type: 'string',
        width: 100,
      },
      {
        field: 'codeGroup_codeGroup',
        title: t('label.codeGroup'),
        type: 'string',
        width: 100,
      },
      {
        field: 'codeGroup_class_class',
        title: t('label.class'),
        type: 'string',
        width: 100,
      },
      {
        field: 'floc_sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 100,
      },
      {
        field: 'floc_sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 100,
      },
      {
        field: 'part_part',
        title: t('label.part'),
        type: 'string',
        width: 100,
      },
      {
        field: 'idwg_idwg',
        title: t('label.idwg'),
        type: 'string',
        width: 100,
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'string',
        width: 100,
      },
      {
        field: 'accessibility_code',
        title: t('label.accessibility'),
        type: 'string',
        width: 100,
      },
      {
        field: 'notif_notif',
        title: t('label.IAN'),
        type: 'string',
        width: 100,
      },
      {
        field: 'notif_externalId',
        title: t('label.notification'),
        type: 'string',
        width: 80,
      },
      {
        field: 'notif_externalStatus',
        title: t('label.notificationStatus'),
        type: 'string',
        width: 80,
      },
      {
        field: 'display2d',
        title: t('label.2d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'display3d',
        title: t('label.3d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'coordinates2d',
        title: t('label.coordinates2d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'markerCoordinates2d',
        title: t('label.markerCoordinates2d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'coordinates3d',
        title: t('label.coordinates3d'),
        width: 80,
        type: 'string',
      },
      {
        field: 'markerCoordinates3d',
        title: t('label.markerCoordinates3d'),
        width: 80,
        type: 'string',
      },
      {
        field: 'maxEndDate',
        title: t('label.maxEndDate'),
        width: 80,
        type: 'date',
        cellRenderer: 'date',
        hidden: true,
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'string',
        width: 80,
        cellRenderer: 'flagStatus',
        hidden: true,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: unknown) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => onOpenDamageClick((rowData as DamageView).id)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      flagStatus: (val: string) => val ? t(`label.flagStatus.${val}`) : '',
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
    },
  };

  mergeDefaultLayout(gridProps, GRID_DEFAULT_LAYOUT_DMG);

  // @ts-ignore
  const columnsAlwaysVisible: TNestedKeys<Damage>[] = useMemo(() => ([
    'action',
    'floc_floc',
    'description',
    'codeGroup_class_class',
    'floc_sector_site_site',
    'floc_sector_site_sector',
    'sector',
    'codeGroup_codeGroup',
    'codeGroup_class_class',
    'notif_notif',
  ]), [damages]);

  useEffect(() => {
    ensureDefaultGridLayout(GRID_NAME, GRID_DEFAULT_LAYOUT_DMG);
  }, []);

  return (
    <div className='h-full'>
      <Grid
        gridName={GRID_NAME}
        queryData={{
          data: damages ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.damage')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      />

      {!!activeDamage && <DamageModal confirmOnExitNoSave isOpen item={activeDamage} onCreatedOrUpdated={fetchDamages}/>}
    </div>
  );
}
