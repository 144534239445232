import {RefIntegrityCondition} from '@app/graphql/__types__/graphql';
import {Tooltip} from '@holis/react-ui';
import {RadBadge, RadDropdownMenu, RadDropdownMenuContent, RadDropdownMenuItem, RadDropdownMenuTrigger} from '@holis/react-ui/rad';
import {LuClock} from 'react-icons/lu';
import {twMerge} from 'tailwind-merge';

export type TMeasurementValueContainer = Readonly<{
	measurementValue?: string;
	measurementColor?: string;
  measurementDescription?: string;
  measurementList?: RefIntegrityCondition[];
  readonly?: boolean;
  className?: string;
  onMeasurementUpdated?: (value: string | null) => void;
}>;

export default function MeasurementValueContainer({measurementColor, className, measurementValue, measurementList, readonly, onMeasurementUpdated, measurementDescription}: TMeasurementValueContainer) {
  return (
    <div className={twMerge('self-stretch rounded-md p-2 bg-gray-200 min-w-14 text-center flex flex-col justify-center', className)} style={{backgroundColor: measurementColor}}>
      {
        !measurementList
          ? (measurementValue ? <div className='font-medium whitespace-pre-line'>{measurementValue}</div> : <LuClock className='font-medium m-auto'/>)
          : <RadDropdownMenu>
            <RadDropdownMenuTrigger disabled={readonly} className='focus:ring-transparent w-full h-full focus:outline-none'>
              <Tooltip content={measurementDescription} placement='left' distance={5} transitionDelay={0} noArrow={!measurementDescription} tooltipClassName={measurementDescription ? '' : 'hidden'}>
                <div className='font-bold text-xs'>{measurementValue ?? <LuClock className='font-medium m-auto'/>}</div>
              </Tooltip>
            </RadDropdownMenuTrigger>
            <RadDropdownMenuContent>
              {measurementList.map(meas => (
                <RadDropdownMenuItem
                  key={meas.condition} className='cursor-pointer' onClick={() => {
                    onMeasurementUpdated?.(meas.condition);
                  }}>
                  <RadBadge style={{backgroundColor: meas.color ?? undefined}} className='w-full justify-center'>
                    <span className='text-sm'>[{meas.condition}] {meas.description}</span>
                  </RadBadge>
                </RadDropdownMenuItem>
              ))}
            </RadDropdownMenuContent>
          </RadDropdownMenu>
      }
    </div>
  );
}
