import {gql} from '@apollo/client';
import {NOTIFICATION_REF_TYPES_COMMON_FIELDS} from './refNotifTypes';
import {NOTIFICATION_REF_SEVERITY_COMMON_FIELDS} from './refNotifSeverities';
import {NOTIFICATION_REF_PRIORITY_COMMON_FIELDS} from './refNotifPriorities';
import {NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS} from './refNotifLikelihoods';

export const NOTIFICATION_REF_MATRIX_COMMON_FIELDS = gql`
    ${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
    ${NOTIFICATION_REF_SEVERITY_COMMON_FIELDS}
    ${NOTIFICATION_REF_PRIORITY_COMMON_FIELDS}
    ${NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS}
    fragment RefNotifMatrixCommonFields on RefNotifMatrix {
        severity
        likelihood
        priority
        typeId
        notifType {
            ...RefNotifTypeCommonFields
        }
        notifLikelihood {
            ...RefNotifLikelihoodCommonFields
        }
        notifPriority {
            ...RefNotifPriorityCommonFields
        }
        notifSeverity {
            ...RefNotifSeverityCommonFields
        }
    }
`;
