import {Damage, Event, EventDamage, FunctionalLocation, InspectionDrawing, Notification, NotificationDamage} from '@app/graphql/__types__/graphql';
import DamageCard from './DamageCard';

type TDamageList = Readonly<{
  damages?: Partial<Damage | EventDamage | NotificationDamage>[];
  readonly?: boolean;
  handleRemoveDamage?: (id: number) => void;
  hasMenuContext?: boolean;
  hideAddNotifBtn?: boolean;
  objectItem?: Partial<Event | Notification | FunctionalLocation | InspectionDrawing>;
}>;

export default function DamageList({damages, readonly, handleRemoveDamage, hasMenuContext, hideAddNotifBtn, objectItem}: TDamageList) {
  return (
    <div className='flex flex-col gap-1'>
      {damages?.map(oDmg => <DamageCard key={oDmg.__typename === 'EventDamage' || oDmg.__typename === 'NotificationDamage' ? oDmg!.damage!.id! : (oDmg as Partial<Damage>).id!} hideAddNotifBtn={hideAddNotifBtn} objectItem={objectItem} hasMenuContext={hasMenuContext} readonly={readonly} dmgObject={oDmg} handleRemoveDamage={() => handleRemoveDamage?.(oDmg.__typename === 'EventDamage' || oDmg.__typename === 'NotificationDamage' ? oDmg!.damage!.id! : (oDmg as Partial<Damage>).id!)}/>)}
    </div>
  );
}
