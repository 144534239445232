import {useEventStore} from '@app/stores/event';
import {t} from 'i18next';
import {RightPanelPage} from '../..';
import {useMutation} from '@apollo/client';
import {UpdateEventByIdMutation} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import ReactQuill from 'react-quill';
import {useState} from 'react';
import EventInfo from '@app/components/Common/Block/Event/EventModal/components/General/EventInfo';
import FlocBlock from '@app/components/Common/Block/Event/EventModal/components/General/ObjectList/FlocBlock';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {LuText} from 'react-icons/lu';

export default function EventPage({visible, readonly}: RightPanelPage) {
  const {editEvent, updateEvent, activeEvent} = useEventStore();
  const [quillContent, setQuillContent] = useState<string>(editEvent?.longDescription ?? '');
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);

  const updateEventDescription = async (longDescription: string) => {
    if (longDescription.trim() === editEvent?.longDescription?.trim()) {
      return;
    }

    const updatedEvent = {...editEvent};
    updatedEvent.longDescription = longDescription;
    try {
      await updateEventByIdApi({
        variables: {
          id: updatedEvent.id,
          data: {
            longDescription: {set: updatedEvent.longDescription},
          },
        },
      });
      updateEvent(updatedEvent);
      AppNotifications.success(t('message.success.eventUpdated'));
    } catch (e) {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  if (!activeEvent) {
    return null;
  }

  return (
    <div className={visible ? '' : 'hidden'}>

      {/* Event Header */}
      <div className='mt-3'>
        <EventInfo event={activeEvent} readonly={readonly}/>
      </div>

      {/* Floc */}

      <div className='mt-6'>
        <FlocBlock event={activeEvent} readonly={readonly}/>
      </div>

      <FormGroupHeader className='mt-6 mb-4'>
        <div className='flex items-center'>
          <LuText size={20} className='mr-2'/> {t('label.longDescription')}
        </div>
      </FormGroupHeader>
      <div className='h-[350px]'>
        <ReactQuill
          key={editEvent?.longDescription}
          readOnly={readonly}
          className={`mr-2 relative${readonly ? ' opacity-50' : ''}`} theme='snow' defaultValue={quillContent}
          style={{height: 'calc(100% - 3rem)'}}
          onChange={setQuillContent}
          onBlur={_ => {
            updateEventDescription(quillContent.trim());
          }}/>
      </div>
    </div>
  );
}
