import {useLazyQuery} from '@apollo/client';
import AppCheckbox from '@app/components/Common/Form/Checkbox';
import {GetEventsByPlanIdQuery, Event, Plan} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENTS_GET_BY_PLAN_ID} from '@app/graphql/requests';
import {FORMAT_DATE_DEFAULT, ROUTE_SCHEDULING_EVENT_DETAIL} from '@app/utils/constants';
// import useEventStore from '@app/stores/Event';
import {IColumn, TCellRenderer, TDataRowTreeList} from '@holis/react-ui';
import moment from 'moment';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import usePlanStore from '@app/stores/plan';
import {RadButton} from '@holis/react-ui/rad';
import BaseEventList from '@app/components/Common/Block/Event/EventBlock/EventList';
import {generatePathWithBaseUrl} from '@app/utils/functions';
import {generatePath} from 'react-router-dom';
import {LuClock, LuExternalLink} from 'react-icons/lu';

type TEventList = Readonly<{
  plan: Partial<Plan>
}>;

export default function EventList({plan}: TEventList) {
  const {t} = useTranslation();
  const {simulatingEvents, events, setEvents} = usePlanStore();
  const [getPlanEventsApi, {data, error, loading, called, refetch}] = useLazyQuery<GetEventsByPlanIdQuery>(WORKORDER_EVENTS_GET_BY_PLAN_ID, {
    variables: {
      planId: plan.id,
    },
    fetchPolicy: 'no-cache',
  });

  const openEventDetail = (event: Partial<Event>) => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_SCHEDULING_EVENT_DETAIL, {number: `${event.event}`})));
  };

  const cellRenderers: Record<string, TCellRenderer<TDataRowTreeList>> = {
    priority: (val: string, _rowData: unknown) => <AppCheckbox disabled checked={!!val}/>,
    action: (_val: string, rowData: unknown) =>
      simulatingEvents?.map(item => item.id).includes((rowData as Partial<Event>).id)
        ? <LuClock className='h-3 w-3 text-xs ml-2'/>
        : <RadButton size='icon' className='h-7 w-7 text-xs' variant='outline' onClick={() => openEventDetail(rowData as Partial<Event>)}><LuExternalLink/></RadButton>,
    date: (val: string) => val ? moment(val).format(FORMAT_DATE_DEFAULT) : '',
    status: (val: string) => val ? t(`label.eventStatuses.${val.toLowerCase()}`) : '',
    stage: (val: string) => val ? t(`label.eventStages.${val.toLowerCase()}`) : '',
    event: (val: string, rowData: unknown) => simulatingEvents?.map(item => item.id).includes((rowData as Partial<Event>).id) ? '' : val,
  };

  const columns: IColumn[] = [
    {
      field: 'action',
      title: t('label.action'),
      cellRenderer: 'action',
      type: 'string',
      filter: false,
      class: 'w-full absolute -left-[4px]',
      width: 60,
    },
    {
      field: 'event',
      title: t('label.eventNum'),
      type: 'string',
      filter: false,
      width: 100,
      cellRenderer: 'event',
    },
    {
      field: 'type.type',
      title: t('label.type'),
      type: 'string',
      filter: false,
      width: 100,
    },
    {
      field: 'description',
      title: t('label.description'),
      type: 'string',
      filter: false,
      width: 120,
    },
    {
      field: 'plannedDate',
      title: t('label.plannedDate'),
      type: 'string',
      filter: false,
      width: 120,
      cellRenderer: 'date',
    },
    {
      field: 'releasedDate',
      title: t('label.processedDate'),
      type: 'string',
      filter: false,
      width: 120,
      cellRenderer: 'date',
    },
    {
      field: 'statusWorkOrder.stage',
      title: t('label.stage'),
      type: 'string',
      filter: false,
      width: 120,
      cellRenderer: 'stage',
    },
    {
      field: 'status',
      title: t('label.status'),
      type: 'string',
      filter: false,
      width: 120,
      cellRenderer: 'status',
    },
  ];

  const getEvents = () => {
    (called ? refetch : getPlanEventsApi)();
  };

  useEffect(() => {
    setEvents(
      [
        ...(data?.events ?? []),
        ...(simulatingEvents ?? []),
      ] as Partial<Event>[],
    );
  }, [simulatingEvents, data]);

  useEffect(() => {
    getEvents();
  }, [plan]);

  return (
    <BaseEventList events={events} simulatingEvents={simulatingEvents} columns={columns} cellRenderers={cellRenderers} error={!!error} isLoading={loading}/>
  );
}
