import {Event, EventDamage, Damage, Picture, UpdateDamageByIdMutation, UpdateEventDamageByIdMutation, NotificationDamage, UpdateNotificationDamageMutation} from '@app/graphql/__types__/graphql';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {TCarouselApi} from '@holis/react-ui';
import {useRef, useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import PictureCarousel from './components/pictures/PictureCarousel';
import GeneralInfo from './components/GeneralInfo';
import Actions from './components/Actions';
import useDamageStore from '@app/stores/damage';
import {EApiOperator} from '@app/utils/enums';
import {OBJ_NEW_ID} from '@app/utils/constants';
import DeleteDamageModal from './components/DeleteDamageModal';
import EventDamageHistory from './components/events/EventDamagesHistory';
import MeasurementBlock from './components/measurement';
import NotificationBlock from './components/Notification';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {useMutation} from '@apollo/client';
import {DAMAGES_UPDATE_BY_ID, NOTIFICATION_DAMAGES_UPDATE, WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {useHolisAuth} from '@holis/auth-client-react';
type TDamageModal = TMultiplePanelsModal<Partial<Damage>> & Readonly<{
    event?: Partial<Event>;
    eventDamage?: Partial<EventDamage>;
    notificationDamage?: Partial<NotificationDamage>;
    onCreatedOrUpdated?: (damage?: Partial<Damage>, operator?: EApiOperator) => void;
    onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
}>

export default function DamageModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventDamage, notificationDamage, ...restProps}: TDamageModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const {user} = useHolisAuth();
  const [setCurrentDateAndUser, setSetCurrentDateAndUser] = useState<boolean>(false);
  const {deleteDamageModalOpen, resetData, changeDeleteDamageModalDisplay, editDamage} = useDamageStore();
  const dmgUpdatedDatas = useRef<Partial<Damage>>({});
  const evtDmgUpdatedDatas = useRef<Partial<EventDamage>>({});
  const notificationDamageUpdatedDatas = useRef<Partial<NotificationDamage>>({});
  const [itemIsSaved, setItemIsSaved] = useState<boolean>(true);
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [updateDamageByIdApi] = useMutation<UpdateDamageByIdMutation>(DAMAGES_UPDATE_BY_ID);
  const [updateEventDamageByIdApi] = useMutation<UpdateEventDamageByIdMutation>(WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID);
  const [updateNotificationDamageApi] = useMutation<UpdateNotificationDamageMutation>(NOTIFICATION_DAMAGES_UPDATE);

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleDamageDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item, EApiOperator.DELETE);
  };

  const handleCloseModal = () => {
    resetData();
    onOpenChange?.(false);
  };

  const handleFieldUpdated = (itemType: 'dmg' | 'meas', field: string, value: unknown) => {
    const isNotification = !!notificationDamage;

    if ((value as { id: string })?.id) {
      value = (value as { id: string }).id;
    }

    if (field === 'qualReadingId' && !isNotification) {
      setSetCurrentDateAndUser(true);
    }

    if (itemType === 'dmg') {
      dmgUpdatedDatas.current[field as keyof Damage] = value;
    } else if (isNotification) {
      if (field === 'qualReadingId') {
        notificationDamageUpdatedDatas.current.qualReadingId = Number(value);
      }
    } else {
      evtDmgUpdatedDatas.current[field as keyof EventDamage] = value;
    }

    setItemIsSaved(false);
  };

  const handleSaveClick = async () => {
    const updateDmgRequest: Record<string, unknown> = {};
    const updateEvtDmgRequest: Record<string, unknown> = {};
    const updateNotificationDamageRequest: Record<string, unknown> = {};

    Object.keys(dmgUpdatedDatas.current).forEach(key => {
      if (key === 'classId') {
        return;
      }

      updateDmgRequest[key] = {set: dmgUpdatedDatas.current[key as keyof Damage] ?? null};
    });
    Object.keys(evtDmgUpdatedDatas.current).forEach(key => {
      updateEvtDmgRequest[key] = {set: evtDmgUpdatedDatas.current[key as keyof EventDamage] ?? null};
    });
    Object.keys(notificationDamageUpdatedDatas.current).forEach(key => {
      updateNotificationDamageRequest[key] = {set: notificationDamageUpdatedDatas.current[key as keyof NotificationDamage] ?? null};
    });

    try {
      if (Object.keys(dmgUpdatedDatas.current).length) {
        await updateDamageByIdApi({variables: {id: item.id!, data: updateDmgRequest}});
      }

      if (Object.keys(evtDmgUpdatedDatas.current).length) {
        updateEvtDmgRequest.reader = {set: user?.username};
        updateEvtDmgRequest.reportingDate = {set: new Date()};
        await updateEventDamageByIdApi({variables: {id: eventDamage!.id!, data: updateEvtDmgRequest}});
      }

      if (Object.keys(notificationDamageUpdatedDatas.current).length) {
        await updateNotificationDamageApi(
          {
            variables: {
              data: updateNotificationDamageRequest,
              dmgId: item.id!,
              notifId: notificationDamage!.notifId!,
            },
          },
        );
      }

      dmgUpdatedDatas.current = {};
      evtDmgUpdatedDatas.current = {};
      setItemIsSaved(true);
      onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE);
      handleCloseModal();
      AppNotifications.success(t('message.success.damageUpdated'));
    } catch (e) {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  return (
    <MultiplePanelsModal
      autoSaveId='modal-damage'
      header={<Header actions={isNew ? null : <Actions damage={item} disableDelete={!prms.damages.delete} onCreatedOrUpdated={onCreatedOrUpdated}/>} damage={item}/>}
      footer={<Footer damage={item} saveButtonEnabled={!itemIsSaved} onCancelClick={() => setIsModalOpened(false)} onSaveClick={handleSaveClick}/>}
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={() => itemIsSaved}
      onOpenChange={opened => {
        if (opened) {
          setIsModalOpened(true);
        } else {
          handleCloseModal();
        }
      }}
      {...restProps}
      isOpen={isModalOpened}
    >
      <div className='flex flex-col w-full overflow-auto'>
        <GeneralInfo readonly={!prms.damages.update} damage={item} onFieldUpdated={(f, v) => handleFieldUpdated('dmg', f, v)}/>
        <NotificationBlock damage={item}/>
      </div>
      <div className='flex flex-col w-full overflow-auto'>
        {(!!eventDamage || !!notificationDamage) && <MeasurementBlock readonly={!prms.damages.measurement} event={event!} damage={editDamage!} eventDamage={eventDamage} notificationDamage={notificationDamage} useCurrentDateAndUser={setCurrentDateAndUser} onFieldUpdated={(f, v) => handleFieldUpdated('meas', f, v)}/>}
        <PictureCarousel
          readonly={!prms.damages.measurement && !prms.damages.update}
          damage={item} event={event} onChanged={onPicturesChanged}
          onCarouselRender={handlePictureCarouselRender}/>
        <EventDamageHistory event={notificationDamage ? undefined : event} damage={editDamage!} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <DeleteDamageModal open={deleteDamageModalOpen} damage={item} changeDeleteDamageModalDisplay={changeDeleteDamageModalDisplay} onDamageDeleted={handleDamageDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
