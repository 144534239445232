import {Damage, GetDamagesByFlocIdsQuery, GetNotificationDamagesByNotifIdQuery, Notification, NotificationDamage, UpdateNotificationByIdMutation} from '@app/graphql/__types__/graphql';
import {EAppFlagStatus, EDamageFilter, EIanLeftSideTab} from '@app/utils/enums';
import {TAppTabItem} from '@app/types/app';
import {useTranslation} from 'react-i18next';
import useNotificationStore, {ZOD_NOTIFICATION_DATAS} from '@app/stores/notification';
import {OBJ_NEW_ID} from '@app/utils/constants';
import NotificationHeader from './NotificationHeader';
import {useEffect, useRef} from 'react';
import {FetchResult, useLazyQuery, useMutation} from '@apollo/client';
import {DAMAGES_GET_BY_FLOC_IDS, NOTIFICATION_DAMAGES_GET_BY_NOTIF_ID, NOTIFICATIONS_UPDATE_BY_ID} from '@app/graphql/requests';
import {searchArray} from '@app/utils/functions';
import SinglePageVerticalTabs from '@app/components/Common/Tabs/SinglePageVerticalTabs';
import PriorityCompliance from './PriorityCompliance';
import {RadForm} from '@holis/react-ui/rad';
import {z} from 'zod';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import _ from 'lodash';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import SpecificData from './SpecificData';
import DamageList from './DamageList';
type TLeftSide = Readonly<{
  notification: Partial<Notification>;
  readonly?: boolean;
}>;

export default function LeftSide({notification, readonly}: TLeftSide) {
  const {setNotificationDamages, setFetchNotificationDamages, fetchNotificationDamages, damageSearchInput, damageActiveFilter, setNotificationFlocDamages, setFetchNotificationFlocDamages, flocDamages, setFilteredNotificationFlocDamages, fetchNotificationFlocDamages, updateNotificationData: updateData, updateNotificationState, submitRequested, setSubmitRequested, handleFieldChange, notificationDamages, activeNotification} = useNotificationStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [updateNotificationByIdApi] = useMutation<UpdateNotificationByIdMutation>(NOTIFICATIONS_UPDATE_BY_ID);
  const [getNotificationFlocDamagesApi] = useLazyQuery<GetDamagesByFlocIdsQuery>(DAMAGES_GET_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [getNotificationDamagesApi] = useLazyQuery<GetNotificationDamagesByNotifIdQuery>(NOTIFICATION_DAMAGES_GET_BY_NOTIF_ID);
  const {t} = useTranslation();

  const zodFormObject = z.object(_.omit(ZOD_NOTIFICATION_DATAS(t), ['typeId']));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), mode: 'onBlur', defaultValues: {
      ...notification,
      description: notification.description ?? '',
      flocId: notification.flocId,
    },
    });

  form.watch((datas, {name, type}) => {
    if (type === 'change') {
      handleFieldChange(name!, datas[name!]);
    }
  });

  const htmlForm = useRef<HTMLFormElement | null>(null);

  const handleFormSubmitSucess = () => {
    // Check if requiredStartDate > requiredEndDate
    const currValues = form.getValues();
    if (currValues?.requiredStartDate && typeof currValues?.requiredStartDate === 'string') {
      currValues.requiredStartDate = new Date(currValues.requiredStartDate);
    }

    if (currValues?.requiredEndDate && typeof currValues?.requiredEndDate === 'string') {
      currValues.requiredEndDate = new Date(currValues.requiredEndDate);
    }

    if (currValues?.requiredStartDate && currValues?.requiredEndDate && currValues.requiredStartDate >= currValues.requiredEndDate) {
      form.setError('requiredEndDate', {message: t('message.error.requiredEndDateMustBeAfterStartDate')});
      return;
    }

    if (updateData && Object.keys(updateData).length) {
      startLoading();
      const actionDate = new Date();
      updateNotificationByIdApi({variables: {id: notification.id, data: updateData}}).then((queryResult: FetchResult<UpdateNotificationByIdMutation>) => {
        updateNotificationState(queryResult.data?.updateOneNotification as Partial<Notification>, actionDate, false);
        AppNotifications.success(t('message.success.notificationUpdated'));
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'));
      }).finally(() => {
        stopLoading();
      });
    }
  };

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit();

      setSubmitRequested(false);
    }
  }, [submitRequested]);

  const items: TAppTabItem[] = [
    {
      title: t('label.header'),
      content: <NotificationHeader readonly={readonly} notification={notification}/>,
      value: EIanLeftSideTab.HEADER,
    },
    {
      title: t('label.priorityCompliance'),
      content: <PriorityCompliance readonly={readonly} notification={notification}/>,
      value: EIanLeftSideTab.PRIORITY_COMPLIANCE,
    },
    {
      title: t('label.damage'),
      content: <DamageList readonly={readonly} notification={notification}/>,
      value: EIanLeftSideTab.DAMAGE,
      counterValue: notificationDamages?.length ?? activeNotification?._count?.notificationDamages ?? 0,
    },
    {
      title: t('label.specificData'),
      content: <SpecificData readonly={readonly} notification={notification}/>,
      value: EIanLeftSideTab.SPECIFIC_DATA,
    },
  ];

  const getNotificationFlocDamages = () => {
    if (notification.id !== OBJ_NEW_ID && notification?.flocId) {
      getNotificationFlocDamagesApi({
        variables: {
          flocIds: [notification?.flocId],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setNotificationFlocDamages(_.sortBy((queryResult.data?.damages ?? []) as Partial<Damage>[], [dmg => dmg.position]));
      });
    } else {
      setNotificationFlocDamages([]);
    }
  };

  // fetch Event Damages
  const getNotificationDamages = () => {
    if (notification.id !== OBJ_NEW_ID) {
      getNotificationDamagesApi({
        variables: {
          notifId: notification.id!,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setNotificationDamages(_.sortBy((queryResult.data?.notificationDamages ?? []) as Partial<NotificationDamage>[], [notifDmg => notifDmg.damage?.position]));
      });
    } else {
      setNotificationDamages([]);
    }
  };

  useEffect(() => {
    setFetchNotificationDamages(getNotificationDamages);
  }, [notification.id]);

  useEffect(() => {
    setFetchNotificationFlocDamages(getNotificationFlocDamages);
  }, [notification.id, notification.flocId]);

  useEffect(() => {
    setFilteredNotificationFlocDamages(searchArray(flocDamages ?? [], damageSearchInput, undefined, ['__typename']).filter((damage: Partial<Damage>) => {
      const displayVal = damage?.flagStatus === EAppFlagStatus.ACTIVE.toString() ? EDamageFilter.ACTIVE : EDamageFilter.FIXED;
      return damageActiveFilter.includes(displayVal);
    }));
  }, [flocDamages, damageActiveFilter, damageSearchInput]);

  useEffect(() => {
    fetchNotificationDamages?.();
  }, [fetchNotificationDamages]);

  useEffect(() => {
    fetchNotificationFlocDamages?.();
  }, [fetchNotificationFlocDamages]);
  return (
    <div className='w-full relative h-full'>
      <RadForm {...form}>
        <form
          ref={htmlForm}
          className='h-full' onSubmit={form.handleSubmit(handleFormSubmitSucess, console.log)}
        >
          <SinglePageVerticalTabs
            items={items}
          />

        </form>
      </RadForm>
    </div>
  );
}
