import {renderToString} from 'react-dom/server';
import {ReactElement} from 'react';
import {utils} from '@holis/react-ui';
import _ from 'lodash';
import {APP_QUERY_ACCESS_TOKEN, FORMAT_DATE_TIME_FILE_NAME} from '../constants';
import axios, {AxiosError} from 'axios';
import {TGetFileFromUrlOptions} from '@app/types/app';
import AppNotifications from '@app/services/notification';
import moment from 'moment';
import colors from 'tailwindcss/colors';
import {Maybe} from 'graphql/jsutils/Maybe';
// import axios from '@app/services/api/AxiosInstance';
// import {AxiosError} from 'axios';

export const {searchArray} = utils;
export function getObjValueByPath(obj: Record<string, unknown>, path: string) {
  // const parts = path.split('.');
  // const {length} = parts;
  // let part;
  // let result: unknown = obj;
  // for (let i = 0; i < length; i++) {
  //   part = parts[i];
  //   if (typeof obj[part] !== 'object' && i < length - 1) {
  //     obj[part] = {};
  //   }

  //   result = obj[part];
  // }

  // return result;
  return _.get(obj, path);
}

export function setObjValueByPath(obj: Record<string, unknown>, path: string, value: unknown) {
  if (!path) {
    return null;
  }

  // const parts = path?.split('.');
  // let part;
  // for (let i = 0; i < parts.length - 1; i++) {
  //   part = parts[i];
  //   if (typeof obj[part] !== 'object') {
  //     obj[part] = {};
  //   }

  //   obj = obj[part] as Record<string, unknown>;
  // }

  // obj[parts[parts.length - 1]] = value;
  _.set(obj, path, value);
}

export function createTreeStoreName(name: string, prefix = 'TREELIST_') {
  return `${prefix}${name}`;
}

export function boolToString(val: boolean, trueVal = 'true', falseVal = 'false'): string {
  return val ? trueVal : falseVal;
}

export function reactElementToString(elem: ReactElement) {
  return renderToString(elem);
}

export async function addTokenToUrl(urlStr: string, getAccessToken: () => Promise<string|undefined>): Promise<string> {
  const accessToken = await getAccessToken();
  if (accessToken) {
    urlStr = changeUrlParam(urlStr, APP_QUERY_ACCESS_TOKEN, accessToken);
  }

  return urlStr;
}

export async function downloadFileFromBlobData(data: Blob, fileName: string) {
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function createObjUrlFromUrl(url: string, options?: TGetFileFromUrlOptions) : Promise<string | null> {
  const {onSuccess, onFail, onEnd, getAccessToken, downloadFileName} = options ?? {};
  try {
    const dataBlob = await createBlobFromUrl(url, {getAccessToken});
    if (dataBlob) {
      onSuccess?.();
      onEnd?.();
      const file = new File([dataBlob], downloadFileName ?? '');
      return URL.createObjectURL(file);
    }

    throw new Error('unknown');
  } catch (err) {
    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  return null;
}

const blobUrlCache: Record<string, Blob> = {};

export async function createBlobFromUrl(url: string, options?: TGetFileFromUrlOptions, nbAttemps = 1) : Promise<Blob | null> {
  const {onSuccess, onFail, onEnd, getAccessToken} = options ?? {};

  if (!options?.noCache && blobUrlCache[url]) {
    setTimeout(() => {
      onSuccess?.();
      onEnd?.();
    }, 500);
    return Promise.resolve(blobUrlCache[url]);
  }

  try {
    if (getAccessToken && !hasUrlParam(url, APP_QUERY_ACCESS_TOKEN)) {
      url = await addTokenToUrl(url, getAccessToken);
    }

    const res = await axios.get(url, {
      responseType: 'blob',
    });
    if (res.status === 200) {
      blobUrlCache[url] = res.data as Blob;
      onSuccess?.();
      onEnd?.();
      return blobUrlCache[url];
    }

    throw new Error('unknown');
  } catch (err: unknown) {
    if (err instanceof AxiosError) {
      const res = err.response;
      if (res?.status === 401 && getAccessToken && nbAttemps < 10) {
        const newToken = await getAccessToken();
        if (newToken) {
          url = changeUrlParam(url, APP_QUERY_ACCESS_TOKEN, newToken);
          return createBlobFromUrl(url, options, nbAttemps + 1);
        }
      }
    }

    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  return null;
}

export async function createBase64FromUrl(url: string, options?: TGetFileFromUrlOptions) : Promise<string | null> {
  const {onSuccess, onFail, onEnd, getAccessToken} = options ?? {};
  try {
    const dataBlob = await createBlobFromUrl(url, {getAccessToken});
    if (dataBlob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string | null);
          onSuccess?.();
          onEnd?.();
        };

        reader.onerror = () => {
          console.log('reject', reader.error);
          reject(reader.error);
          // onFail?.(reader.error);
          // onEnd?.();
        };

        reader.readAsDataURL(dataBlob);
      });
    }

    throw new Error('unknown', {cause: url});
  } catch (err) {
    console.log('catch error', err);
    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  return null;
}

export async function downloadFileFromUrl(url: string, fileName: string, options?: TGetFileFromUrlOptions) {
  const {onSuccess, onFail, onEnd} = options ?? {};
  try {
    const dataBlob = await createBlobFromUrl(url, {getAccessToken: options?.getAccessToken});
    if (dataBlob) {
      downloadFileFromBlobData(dataBlob, fileName);
      onSuccess?.();
    } else {
      throw new Error('unknown', {cause: url});
    }
  } catch (err) {
    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
}

export async function openFileFromUrlInNewTab(url: string, tabName: string, {onSuccess, onFail, onEnd, print, translation, getAccessToken, downloadFileName: _downloadFileName}: TGetFileFromUrlOptions) {
  try {
    let errorMessage = 'unknown';
    const fileURL = await createObjUrlFromUrl(url, {getAccessToken});
    if (fileURL) {
      // // create <a> element dynamically
      // const fileLink = document.createElement('a');
      // fileLink.href = fileURL;
      // fileLink.target = '_blank';

      // if (downloadFileName || tabName.endsWith('.pdf')) {
      //   // suggest a name for the downloaded file
      //   fileLink.download = downloadFileName ?? tabName;
      // }

      // // simulate click
      // try {
      //   console.log('click link');
      //   fileLink.click();
      //   onSuccess?.();
      //   onEnd?.();
      //   return;
      // } catch (err: unknown) {
      //   console.log(err);
      //   if (err instanceof Error) {
      //     errorMessage = err.message;
      //   }
      // }
      const tab = window.open();
      if (tab) {
        tab.document.title = tabName;
        tab.location.href = fileURL;
        tab.addEventListener('load', () => {
          onSuccess?.();
          tab.focus();
          if (print) {
            tab.print();
          }
        });
        onEnd?.();
        return;
      }

      errorMessage = 'newTabBlocked';
    }

    throw new Error(errorMessage, {cause: url});
  } catch (err: unknown) {
    if (err instanceof Error && err.message === 'newTabBlocked') {
      AppNotifications.warning(translation?.('message.warning.newTabBlocked') ?? 'Opening new tab blocked ! Please check your browser permission to allow this website to open new tab.', {
        duration: 5000,
      });
    } else if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  // axios.get(url, {responseType: 'blob'}).then((res: AxiosResponse) => {
  //   let errorMessage = 'unknown';
  //   if (res.status === 200) {
  //     const fileURL = URL.createObjectURL(res.data);
  //     if (fileURL) {
  //       const tab = window.open();
  //       console.log(tab);
  //       if (tab) {
  //         tab.document.title = tabName;
  //         tab.location.href = fileURL;
  //         tab.addEventListener('load', () => {
  //           onSuccess?.();
  //           tab.focus();
  //           if (print) {
  //             tab.print();
  //           }
  //         });
  //         return;
  //       }

  //       errorMessage = 'newTabBlocked';
  //     }
  //   }

  //   throw new Error(errorMessage, {cause: res.data});
  // }).catch((err: Error) => {
  //   if (err.message === 'newTabBlocked') {
  //     AppNotifications.warning(translation?.('message.warning.newTabBlocked') ?? 'Opening new tab blocked ! Please check your browser permission to allow this website to open new tab.');
  //   } else {
  //     onFail?.(err);
  //   }
  // }).finally(() => {
  //   onEnd?.();
  // });
}

export function getFileNameWithoutExtension(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export function getFileExtension(fileName: string) {
  return fileName.split('.').pop()?.toLowerCase() ?? '';
}

export function isValidUnit(unit?: string | null) {
  const realUnit = unit?.trim() ?? '';
  return realUnit !== '' && realUnit !== '-';
}

export function generatePathWithBaseUrl(url: string) {
  const baseUrl = import.meta.env.BASE_URL;
  return `${baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`}${url.startsWith('/') ? url.substring(1) : url}`;
}

export function generateAbsoluteUrlWithBaseUrl(url: string) {
  const baseUrl = import.meta.env.BASE_URL;
  return `${location.protocol}//${location.host}${baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`}${url.startsWith('/') ? url.substring(1) : url}`;
}

export function imageObjToDataUrl(img: HTMLImageElement, outputMimeType?: string, outputQuality?: number) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.height = img.naturalHeight;
  canvas.width = img.naturalWidth;
  ctx?.drawImage(img, 0, 0);
  return canvas.toDataURL(outputMimeType, outputQuality);
}

export function stringNumFormat(val?: string, defaultEmptyValue: string | null | number = null) : string | null | number {
  let value: string | null | number = val?.trim().replace(',', '.') ?? '';
  if (value === '') {
    value = defaultEmptyValue;
  }

  return value;
}

export function hasUrlParam(urlStr:string, param: string): boolean {
  const url = new URL(urlStr);
  const params = url.searchParams;

  return params.has(param);
}

export function changeUrlParam(urlStr:string, param: string, value: string | number): string {
  const url = new URL(urlStr);
  const params = url.searchParams;

  if (params.has(param)) {
    params.set(param, String(value));
  } else {
    params.append(param, String(value));
  }

  // change the search property of the main url
  url.search = params.toString();

  // the new url string
  return url.toString();
}

export function getCurentDateTimeFileName() {
  return moment().format(FORMAT_DATE_TIME_FILE_NAME);
}

interface ILocalOptions extends Intl.CollatorOptions {
  locales?: string;
}
export const localeIncludes = (
  string: string,
  searchInput: string,
  {locales = undefined, ...options}: ILocalOptions = {
    sensitivity: 'base',
  },
) => {
  if (!string) {
    return false;
  }

  if (searchInput === undefined || searchInput === null) {
    throw new Error('localeIncludes requires at least 2 parameters');
  }

  const stringLength = string.length;
  const searchInputLength = searchInput.length;
  const lengthDiff = stringLength - searchInputLength;

  for (let i = 0; i <= lengthDiff; i++) {
    if (string.substring(i, i + searchInputLength).localeCompare(searchInput, locales, options) === 0) {
      return true;
    }
  }

  return false;
};

export const tailwindColorToBgFgStyle = (color?: Maybe<string>) => {
  if (!color) {
    return {
      backgroundColor: colors.gray[200],
      color: colors.gray[700],
    };
  }

  const rgbBgColor = colors[color as keyof typeof colors]?.[200] ?? colors.gray[200];
  const rgbFgColor = colors[color as keyof typeof colors]?.[700] ?? colors.gray[700];
  return {
    backgroundColor: rgbBgColor,
    color: rgbFgColor,
  };
};

export const isStringNotEmpty = (s?: unknown) => typeof s === 'string' && s !== '';

export const isFalsyOrWhiteSpace = (s?: string) => !s || s.trim() === '';
