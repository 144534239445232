import {gql} from '@apollo/client';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS, SCHEDULING_PLAN_FLOCS_COMMON_FIELDS} from '../fragments';

export const SCHEDULING_PLAN_FLOCS_GET_BY_PLAN_ID = gql`
${SCHEDULING_PLAN_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
query GetPlanFlocsByPlanId($planId: Int!) {
  planFlocs (where: {planId: {equals: $planId}}, orderBy:  [{sort: {sort: asc}}]) {
    ...PlanFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
}`;

export const SCHEDULING_PLAN_FLOCS_CREATE = gql`
${SCHEDULING_PLAN_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
mutation CreatePlanFloc($data: PlanFlocUncheckedCreateInput!) {
  createOnePlanFloc(data: $data) {
    ...PlanFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
}`;

export const SCHEDULING_PLAN_FLOCS_CREATE_MANY = gql`
mutation CreatePlanFlocs($data: [PlanFlocCreateManyInput!]!) {
  createManyPlanFloc(data: $data) {
    count
  }
}`;

export const SCHEDULING_PLAN_FLOCS_DELETE_BY_FLOC_IDS_AND_PLAN_ID = gql`
mutation DeletePlanFlocsByFlocIdsAndPlanId($planId: Int!, $flocIds: [Int!]) {
  deleteManyPlanFloc(where: {planId: {equals: $planId}, flocId: {in: $flocIds}}) {
      count
  }
}`;

export const SCHEDULING_PLAN_FLOCS_DELETE_ONE = gql`
  mutation DeleteOnePlanFloc($planId: Int!, $flocId: Int!) {
    deleteOnePlanFloc(where: {planId_flocId: {planId: $planId, flocId: $flocId}}) {
      id
    }
  }
`;

export const SCHEDULING_PLAN_FLOCS_DELETE_ONE_BY_ID = gql`
mutation DeleteOnePlanFlocById($id: Int!) {
  deleteOnePlanFloc(where: {id: $id}) {
    id
  }
}
`;

export const SCHEDULING_PLAN_FLOCS_UPDATE_BY_ID = gql`
${SCHEDULING_PLAN_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
mutation UpdateOnePlanFlocById($id: Int!, $data: PlanFlocUncheckedUpdateInput!) {
  updateOnePlanFloc(where: {id: $id}, data: $data) {
    ...PlanFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
}
`;

export const SCHEDULING_PLAN_FLOCS_GET_COUNTER = gql`
query GetPlanFlocsCounter {
  aggregatePlanFloc {
    _count {
      id
    }
  }
}
`;
