import {gql} from '@apollo/client';

export const PARAMETERS_COMMON_FIELDS = gql`
  fragment ParameterCommonFields on Parameter {
    id
    code
    description
    value
  }
`;

