import {useMutation, useQuery} from '@apollo/client';
import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu';
import AppCombobox from '@app/components/Common/Form/AppCombobox';
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper';
import {AppListItem} from '@app/components/Common/Form/list-item';
import ImageEditor from '@app/components/Common/ImageEditor';
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {
  GetAllGridsAutocompleteQuery,
  Grid,
  Picture,
  PictureUncheckedUpdateInput,
  UpdatePictureByIdMutation,
} from '@app/graphql/__types__/graphql';
import {GRIDS_GET_ALL_AUTOCOMPLETE, PICTURES_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import {useLayoutStore} from '@app/stores/layout';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import {EImportService, EPictureContentType} from '@app/utils/enums';
import {addTokenToUrl, createBase64FromUrl} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import {
  RadButton,
  RadCarousel,
  RadCarouselContent,
  RadCarouselItem,
  RadCarouselNext,
  RadCarouselPrevious,
  RadDialog,
  RadDialogClose,
  RadDialogContent,
  RadDialogDescription,
  RadDialogFooter,
  RadDialogHeader,
  RadDialogTitle,
  RadDropdownMenuItem,
  RadTextarea,
} from '@holis/react-ui/rad';
import {ImageSource} from '@pqina/pintura';
import {PinturaEditor} from '@pqina/react-pintura';
import {t} from 'i18next';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {LuDownload} from 'react-icons/lu';

type TProps = Readonly<{
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  onSaved?: (updatedPic: Partial<Picture>) => void;
  picture: Picture;
  allPictures?: Partial<Picture>[];
}>;

export default function PictureEditionModal({onSaved, picture, ...props}: TProps) {
  const editorRef = useRef<PinturaEditor>(null);
  const [picSrc, setPicSrc] = useState<ImageSource | null>();
  const {startLoading, stopLoading} = useLayoutStore();
  const [updatePictureByIdApi] = useMutation<UpdatePictureByIdMutation>(PICTURES_UPDATE_BY_ID);
  const [defaultSelectedGrid, setDefaultSelectedGrid] = useState<string | number | null | undefined>();
  const [updateData, setUpdateData] = useState<Partial<PictureUncheckedUpdateInput>>();
  const [editPicture, setEditPicture] = useState<Partial<Picture>>();
  const [gridsListItems, setGridsListItems] = useState<AppListItem[]>([]);
  const {uploadDownloadService} = useDataStore();
  const {loading: gridsLoading} = useQuery<GetAllGridsAutocompleteQuery>(GRIDS_GET_ALL_AUTOCOMPLETE, {
    onCompleted(data) {
      setGridsListItems(data.grids.map((grid: Partial<Grid>) => ({
        ...grid,
        label: `[${grid.grid}]${grid.description ? ` - ${grid.description}` : ''}`,
        value: grid.id,
      } as AppListItem)));
    },
  });
  const [picSrcs, setPicSrcs] = useState<Record<number, string>>();

  const {getAccessToken} = useHolisAuth();

  const maxDescLength = 500;

  useEffect(() => {
    getDefaultSelectedGrid();
  }, [editPicture, gridsListItems]);

  const downloadEditedImage = async () => {
    const fileName = editPicture!.docName;
    const res = await editorRef.current?.editor.processImage();
    const fileWithAnnotation = res?.dest;
    if (!editPicture || !editorRef.current || !fileWithAnnotation) {
      return;
    }

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(fileWithAnnotation);
    link.download = fileName as string;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode?.removeChild(link);
    }, 0);
  };

  const getDefaultSelectedGrid = (): number | undefined => {
    if (!editPicture?.grid) {
      setDefaultSelectedGrid(undefined);
      return;
    }

    const result = gridsListItems.find(appListItem => editPicture!.grid!.id === appListItem.value);

    setDefaultSelectedGrid(result?.value);
  };

  const handleChange = (field: keyof PictureUncheckedUpdateInput, value: unknown) => {
    if (!editPicture) {
      return;
    }

    setEditPicture({
      ...editPicture,
      [field]: value,
    });
    setUpdateData({
      ...updateData,
      [field]: field === 'annotation' ? value : {
        set: value,
      },
    });
  };

  const handleSave = async () => {
    const res = await editorRef.current?.editor.processImage();
    const imageState = res?.imageState ?? {};
    const isStateChanged = !_.isEqual(imageState, editPicture?.annotation ?? {});
    if (!editPicture || !editorRef.current || !((updateData && Object.keys(updateData).length) || isStateChanged)) {
      return props.onOpenChange?.(false);
    }

    const fileWithAnnotation = res?.dest;
    startLoading();

    return updatePictureByIdApi({variables: {id: editPicture.id, data: Object.assign(isStateChanged ? {annotation: imageState} : {}, updateData)}}).then(async res => {
      const newPic = res.data?.updateOnePicture;
      if (isStateChanged && fileWithAnnotation) {
        await uploadPictureWithAnnotation(fileWithAnnotation);
      }

      AppNotifications.success(t('message.success.pictureUpdated'));
      onSaved?.(newPic as Partial<Picture>);
      stopLoading();
      props.onOpenChange?.(false);
    }).catch(() => {
      stopLoading();
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const uploadPictureWithAnnotation = (uploadFile: File): Promise<boolean> => uploadDownloadService!.uploadFile(uploadFile, {
    contentType: EPictureContentType.ANNOTATION,
    fileIds: [editPicture!.id!],
  }, EImportService.UPLOAD_PICTURE).then(_response => true).catch((_err: Error) => false);

  const getPicSrc = async (picture: Partial<Picture>) => {
    if (picture.id) {
      startLoading();
      generatePicSrc(picture)
        .then(url => setPicSrc(url))
        .catch((error: unknown) => {
          console.log(error);
          setPicSrc(null);
        })
        .finally(stopLoading);
    }
  };

  const generatePicSrc = async (pic: Partial<Picture>) => {
    if (pic.id) {
      const urlWithToken = await addTokenToUrl(`${OptimusClientConfig.current.pictureBaseUrl}/${pic.id}?compressed=1`, getAccessToken);
      const url = await createBase64FromUrl(urlWithToken);
      return url;
    }
  };

  const setAllPicSrcs = async () => {
    if (props.allPictures) {
      for (const pic of props.allPictures) {
        generatePicSrc(pic).then(url => {
          setPicSrcs(prevState => ({
            ...prevState,
            [pic.id!]: url!,
          }));
        });
      }
    }
  };

  useEffect(() => {
    setEditPicture({
      ...picture,
    });
  }, [picture]);

  useEffect(() => {
    if (editPicture) {
      getPicSrc(editPicture);
      setUpdateData({});
    }
  }, [editPicture?.id]);

  useEffect(() => {
    setAllPicSrcs();
  }, [props.allPictures]);

  console.log('editPicture', editPicture);

  return (
    <RadDialog open={props.open} onOpenChange={props.onOpenChange}>
      <RadDialogContent
        className='flex flex-col min-w-[80%] h-[90vh]'
        onPointerDownOutside={e => e.preventDefault()}
        onEscapeKeyDown={e => e.preventDefault()}>
        <RadDialogHeader>
          <RadDialogTitle>
            {t('label.pictureEdition')}

            <ActionThreeDotsMenu>
              <RadDropdownMenuItem onClick={downloadEditedImage}>
                <LuDownload className='mr-2'/>{t('label.downloadPicture')}
              </RadDropdownMenuItem>
            </ActionThreeDotsMenu>
          </RadDialogTitle>

          <RadDialogDescription>
            {editPicture?.functionalLocation?.floc}
          </RadDialogDescription>
        </RadDialogHeader>

        <div className='flex w-full gap-2 h-full flex-wrap'>
          <div className='flex flex-col gap-2 flex-1 min-w-[400px]'>
            <span className='text-xs font-bold text-muted-foreground'>
              {/* {t('label.pictureEdition')} */}
              &nbsp;
            </span>

            <SpinnerLoaderComponent error={picSrc === null} isLoading={typeof picSrc === 'undefined'}>
              {!!picSrc && <ImageEditor
                src={picSrc}
                imageState={editPicture?.annotation}
                editorRef={editorRef}
              // onProcess={handleEditorProcess}
              />}
            </SpinnerLoaderComponent>
            <RadCarousel className='mx-12'>
              <RadCarouselContent className='gap-2'>
                {
                  (props.allPictures ?? []).map((pic: Partial<Picture>) => (
                    <RadCarouselItem
                      key={pic.id}
                      className='basis-[100px]'
                    >
                      <div
                        style={{backgroundImage: picSrcs?.[pic.id!] ? `url('${picSrcs[pic.id!]}')` : undefined}}
                        className='aspect-square rounded border bg-gray-100 bg-cover bg-center bg-no-repeat cursor-pointer hover:brightness-125 hover:scale-110 transition-transform'
                        onClick={() => setEditPicture({...pic})}>
                        <SpinnerLoader isLoading={!picSrcs?.[pic.id!]} className='bg-transparent relative fill-gray-300' spinnerClassName='fill-gray-300'/>
                      </div>
                    </RadCarouselItem>
                  ))
                }</RadCarouselContent>
              <RadCarouselPrevious/>
              <RadCarouselNext/>
            </RadCarousel>
          </div>

          <div className='flex flex-col gap-2 w-[350px]'>
            <span className='text-xs font-bold text-muted-foreground'>
              {t('label.details')}
            </span>

            <div className='flex flex-col items-center gap-4 rounded-md border h-full p-2'>
              {/* Grid */}
              <InputLabelWrapper label={t('label.grid')} className='w-full'>
                <AppCombobox
                  items={gridsListItems}
                  placeholder={t('label.selectGrid')}
                  loading={gridsLoading}
                  value={defaultSelectedGrid}
                  onSelectedItemChanged={item => handleChange('gridId', item?.id ?? null)}
                />
              </InputLabelWrapper>

              {/* Description */}
              <InputLabelWrapper
                className='size-full'
                label={t('label.description')}
              >
                <RadTextarea
                  className='h-full resize-none'
                  placeholder={t('label.description')}
                  value={editPicture?.description ?? ''}
                  maxLength={maxDescLength}
                  onChange={e => handleChange('description', e.target.value)}
                />
              </InputLabelWrapper>
            </div>
          </div>
        </div>

        <RadDialogFooter>
          <RadDialogClose asChild>
            <RadButton variant='outline'>
              {t('label.cancel')}
            </RadButton>
          </RadDialogClose>

          <RadButton onClick={handleSave}>
            {t('label.save')}
          </RadButton>
        </RadDialogFooter>
      </RadDialogContent>
    </RadDialog>
  );
}
