import {gql} from '@apollo/client';
import {NOTIFICATION_REF_SEVERITY_COMMON_FIELDS} from '../fragments';

export const NOTIFICATION_REF_MATRICES_GET_MANY = gql`
${NOTIFICATION_REF_SEVERITY_COMMON_FIELDS}
query GetAllRefNotifMatrices($orderBy: [RefNotifMatrixOrderByWithRelationInput!], $where: RefNotifMatrixWhereInput) {
    refNotifMatrices (orderBy: $orderBy, where: $where) {
      ...RefNotifMatrixCommonFields
    }
}`;

export const NOTIFICATION_REF_MATRICES_GET_COUNTER = gql`
query GetRefNotifMatricesCounter {
  aggregateRefNotifMatrix {
    _count {
      _all
    }
  }
}
`;
