/* eslint-disable complexity */
import {useLazyQuery, useMutation} from '@apollo/client';
import CmlModal from '@app/components/Common/Block/Cml/CmlModal';
import DamageModal from '@app/components/Common/Block/Damage/DamageModal';
import DeleteEventModal from '@app/components/Common/Block/Event/EventModal/components/DeleteEventModal';
import Footer from '@app/components/Common/Block/Event/EventModal/components/Footer';
import EventWorkflowTableModal from '@app/components/Common/Block/Event/EventModal/components/Workflow';
import InspectionPointModal from '@app/components/Common/Block/InspectionPoint/InspectionPointModal';
import ItemsSummary, {TSummaryItem} from '@app/components/Common/ItemSummary';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import PageContent from '@app/components/Layout/PageContent';
import {PICTURES_GET_BY_EVT_ID, WORKORDER_EVENT_CMLS_CREATE, WORKORDER_EVENT_CMLS_GET_BY_EVT_ID, WORKORDER_EVENT_DAMAGES_CREATE, WORKORDER_EVENT_DAMAGES_GET_BY_EVT_ID, WORKORDER_EVENT_DOCUMENTS_GET_BY_EVT_ID, WORKORDER_EVENT_FLOCS_CREATE, WORKORDER_EVENT_FLOCS_GET_BY_EVT_ID, WORKORDER_EVENT_INSPECTION_DRAWINGS_GET_BY_EVT_ID, WORKORDER_EVENT_TASKS_GET_BY_EVT_ID, WORKORDER_EVENT_WORKFLOWS_CREATE, WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID, WORKORDER_EVENTS_GET_DETAIL_BY_NUMBER, WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import {WORKORDER_EVENT_INSPECTION_POINTS_CREATE, WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_EVT_ID} from '@app/graphql/requests/eventInspectionPoints';
import AppNotifications from '@app/services/notification';
import useCmlStore from '@app/stores/cml';
import useDamageStore from '@app/stores/damage';
import {useEventStore} from '@app/stores/event';
import useInspectionPointStore from '@app/stores/insp';
import {useLayoutStore} from '@app/stores/layout';
import useNotificationStore from '@app/stores/notification';
import {TAdditionalActions} from '@app/types/app';
import {EAdditionalAction, EApiOperator, EWORKORDER_EVENT_STATUS} from '@app/utils/enums';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuDownload, LuGitFork, LuRefreshCw, LuTrash2} from 'react-icons/lu';
import {MdOutlineRefresh} from 'react-icons/md';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import _ from 'lodash';
import {OBJ_NEW_ID, ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL} from '@app/utils/constants';
import {CmlCreateModal} from '@app/components/Modal/Inspection/Cml/Create';
import useIdwgStore from '@app/stores/idwg';
import NotificationNewModal from '@app/components/Common/Block/Notification/NotificationNewModal';
import {DamageCreateModal} from '@app/components/Modal/Inspection/Damage/Create';
import CmlSelectionModal from '@app/components/Common/Block/Cml/EventCmlSelectionModal';
import EventDamageSelectionModal from '@app/components/Common/Block/Damage/EventDamageSelectionModal';
import EventDocumentSelectionModal from '@app/components/Common/Block/Document/DocumentBlock/EventDocumentSelectionModal';
import UserAssignModal from '@app/components/Common/Block/Event/UserAssignModal';
import {Event, CreateEventCmlMutation, CreateEventDamageMutation, CreateEventInspectionPointMutation, GenerateEventWorkpackByIdMutation, GetEventInspectionDrawingsByEvtIdQuery, GetEventCmlsByEvtIdQuery, GetEventDamagesByEvtIdQuery, GetPicturesByEvtIdQuery, GetEventFlocsByEvtIdQuery, GetEventTasksByEvtIdQuery, GetEventInspectionPointsByEvtIdQuery, GetEventDetailByNumberQuery, CreateEventWorkflowMutation, CreateEventFlocMutation, GetEventDocumentsByEvtIdQuery, UpdateEventByIdMutation, EventDocument, Cml, Damage, InspectionPoint, Picture, EventInspectionDrawing, EventCml, EventDamage, EventFloc, EventTask, EventInspectionPoint, UserInfo, Notification} from '@app/graphql/__types__/graphql';
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels';
import EventInspectionDrawingSelectionModal from '@app/components/Common/Block/InspectionDrawing/InspectionDrawingBlock/EventInspectionDrawingSelectionModal';
import EventActionConfirmModal from '@app/components/Common/Block/Event/EventModal/components/EventActionConfirmModal';
import ConfirmModal from '@app/components/Modal/Confirm';
import Error404 from '@app/pages/Errors/NotFound';
import {navigateOneSegmentBelow} from '@app/utils/functions/navigation';
import useReportGeneration, {ItemType} from '@app/utils/hooks/useReportGeneration';
import ReportGeneratingMesaggeWrapper from '@app/components/Common/Block/Reporting/ReportGeneratingMesaggeWrapper';

type routeParams = {
  number: string;
};

export default function SchedulingEventDetailsPage() {
  const {number: eventNumber} = useParams<routeParams>();
  const location = useLocation();
  const prms = useUserPermissions();
  const navigate = useNavigate();
  const [eventNotFound, setEventNotFound] = useState<boolean>(false);
  const {t, i18n} = useTranslation();
  const [regenerateConfirmModalOpened, setRegenerateConfirmModalOpened] = useState(false);
  // const rightPanelRef = useRef<never>(null);
  const {resetData, setRightSideWidth, setEventFlocs, setEventTasks, setEventDamages, setEventCmls, setEventInspectionDrawings, setFetchEventTasks, setFetchEventFlocs, setFetchEventDamages, setCreateEventWorkflowFunc, setFetchEventInspectionDrawings, setFetchEventCmls, changeDeleteEventModalDisplay, changeWorkflowModalDisplay, eventInspectionDrawings, eventDocuments, eventNotifications, setActiveEvent, eventTasks, eventInspectionPoints, deleteEventModalOpen, fetchEventCmls, fetchEventDamages, fetchEventInspectionPoints, eventCmls, eventDamages, activeEvent, eventFlocs, eventWorkflowModalDisplayed, fetchEventNotifications, setEventInspectionPoints, setFetchEventInspectionPoints, changeLastCmlUpdated, changeLastDamageUpdated, setFetchPictures, setPictures, fetchPictures, setEventDocuments, setFetchEventDocuments, cmlSelectionModalDisplayed, damageSelectionModalDisplayed, documentSelectionModalDisplayed, assignModalOpened, setAssignModalOpened, fetchEventInspectionDrawings, changeLastDamagePicUpdated, idwgSelectionModalDisplayed, setPointIdwgIds, setPointFlocIds, actionConfirmModalOpened, actionConfirmModalActionKey, actionConfirmModalCallback, actionConfirmModalParams, setActionConfirmModalOpened, createEventWorkflowFunc, checkActiveEventReportingValidity, eventValidationErrors, eventValidationWarnings, checkActiveEventPreparationValidity, resetActiveEventValidation} = useEventStore();
  const {changeCmlMarkupsDisplay, changeDamageMarkupsDisplay} = useIdwgStore();
  const [createEventCmlApi] = useMutation<CreateEventCmlMutation>(WORKORDER_EVENT_CMLS_CREATE);
  const [createEventDamageApi] = useMutation<CreateEventDamageMutation>(WORKORDER_EVENT_DAMAGES_CREATE);
  const [createEventInspectionPointApi] = useMutation<CreateEventInspectionPointMutation>(WORKORDER_EVENT_INSPECTION_POINTS_CREATE);
  const [generateWorkpackById] = useMutation<GenerateEventWorkpackByIdMutation>(WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID);
  const {activeInspectionPoint, setActiveInspectionPoint} = useInspectionPointStore();
  const {activeNotification, setActiveNotification} = useNotificationStore();
  const {setActiveCml, activeCml} = useCmlStore();
  const {setActiveDamage, activeDamage} = useDamageStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [getEventInspectionDrawingsApi] = useLazyQuery<GetEventInspectionDrawingsByEvtIdQuery>(WORKORDER_EVENT_INSPECTION_DRAWINGS_GET_BY_EVT_ID);
  const [getEventCmlsApi] = useLazyQuery<GetEventCmlsByEvtIdQuery>(WORKORDER_EVENT_CMLS_GET_BY_EVT_ID);
  const [getEventDamagesApi] = useLazyQuery<GetEventDamagesByEvtIdQuery>(WORKORDER_EVENT_DAMAGES_GET_BY_EVT_ID);
  const [getPicturesApi] = useLazyQuery<GetPicturesByEvtIdQuery>(PICTURES_GET_BY_EVT_ID);
  const [getEventFlocsApi] = useLazyQuery<GetEventFlocsByEvtIdQuery>(WORKORDER_EVENT_FLOCS_GET_BY_EVT_ID);
  const [getEventTasksApi] = useLazyQuery<GetEventTasksByEvtIdQuery>(WORKORDER_EVENT_TASKS_GET_BY_EVT_ID);
  const [getEventInspectionPointsApi] = useLazyQuery<GetEventInspectionPointsByEvtIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_EVT_ID);

  const [getEventDetailApi] = useLazyQuery<GetEventDetailByNumberQuery>(WORKORDER_EVENTS_GET_DETAIL_BY_NUMBER, {
    variables: {
      number: eventNumber,
    },
    fetchPolicy: 'no-cache',
  });
  const [createEventWorkflowApi] = useMutation<CreateEventWorkflowMutation>(WORKORDER_EVENT_WORKFLOWS_CREATE);
  const [createEventFlocApi] = useMutation<CreateEventFlocMutation>(WORKORDER_EVENT_FLOCS_CREATE);
  const [getEventDocumentsApi] = useLazyQuery<GetEventDocumentsByEvtIdQuery>(WORKORDER_EVENT_DOCUMENTS_GET_BY_EVT_ID);
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);

  const {hasReport, isReportGenerating, launchReportGeneration, downloadReport} = useReportGeneration(ItemType.event, activeEvent?.id, activeEvent?.event);

  const getEventDocuments = () => {
    getEventDocumentsApi({
      variables: {
        evtId: activeEvent!.id,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      setEventDocuments((queryResult.data?.eventDocuments ?? []) as Partial<EventDocument>[]);
    });
  };

  const userIsReadonly = useMemo(() => {
    const [stage] = activeEvent?.status?.split('_') ?? '';
    if (stage === 'PEND') {
      return !prms.events.release;
    }

    switch (activeEvent?.status) {
      case EWORKORDER_EVENT_STATUS.PREP_PROCESSING:
        return !prms.events.prepare;
      case EWORKORDER_EVENT_STATUS.PREP_REVIEWING:
        return !prms.events.review;
      case EWORKORDER_EVENT_STATUS.PREP_APPROVING:
        return !prms.events.approve;
      case EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN:
      case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION:
      case EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET:
        return !prms.events.assign;
      case EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION:
        return !prms.events.sendtoexec;
      case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET:
        return !prms.events.execinsp;
      case EWORKORDER_EVENT_STATUS.REP_PROCESSING:
        return !prms.events.preparereport;
      case EWORKORDER_EVENT_STATUS.REP_REVIEWING:
        return !prms.events.reviewreport;
      case EWORKORDER_EVENT_STATUS.REP_APPROVING:
      case EWORKORDER_EVENT_STATUS.EXER:
        return !prms.events.approvereport;
      default:
        return true;
    }
  }, [prms, activeEvent?.status]);

  const handleInspectionPointModalClose = () => {
    setActiveInspectionPoint();
  };

  const handleCmlModalClose = () => {
    setActiveCml();
  };

  const handleNotificationModalClose = () => {
    setActiveNotification();
  };

  const handleDamageModalClose = () => {
    setActiveDamage();
  };

  const handleCmlCreatedOrUpdated = (cml?: Partial<Cml>, operator?: EApiOperator) => {
    if (cml) {
      changeLastCmlUpdated(cml);
    }

    if (operator === EApiOperator.CREATE) {
      createEventCmlApi({
        variables: {
          cmlId: cml!.id,
          evtId: activeEvent!.id,
        },
      }).finally(() => {
        fetchEventCmls?.();
        fetchPictures?.();
        changeCmlMarkupsDisplay(true);
      });
    } else {
      fetchEventCmls?.();
    }
  };

  const handleDamageCreatedOrUpdated = (damage?: Partial<Damage>, operator?: EApiOperator) => {
    console.log('damage changed ', damage);
    if (damage) {
      changeLastDamageUpdated(damage);
    }

    if (operator === EApiOperator.CREATE) {
      createEventDamageApi({
        variables: {
          dmgId: damage!.id,
          evtId: activeEvent!.id,
        },
      }).finally(() => {
        fetchEventDamages?.();
        fetchPictures?.();
        changeDamageMarkupsDisplay(true);
      });
    } else {
      fetchEventDamages?.();
    }
  };

  const handleInspectionPointCreatedOrUpdated = (inspectionPoint?: Partial<InspectionPoint>, operator?: EApiOperator) => {
    if (operator === EApiOperator.CREATE) {
      createEventInspectionPointApi({
        variables: {
          inspId: inspectionPoint!.id,
          evtId: activeEvent!.id,
        },
      }).finally(() => {
        fetchEventInspectionPoints?.();
        fetchPictures?.();
      });
    } else {
      fetchEventInspectionPoints?.();
    }
  };

  const handleNotificationCreatedOrUpdated = (notification: Partial<Notification>) => {
    fetchEventNotifications?.();
    const notifUrl
      = import.meta.env.BASE_URL.replace(/\/$/, '')
      + ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notification.notif!);
    window.open(notifUrl);
  };

  const handleCmlPicturesChanged = () => {
    fetchEventCmls?.();
    fetchPictures?.();
  };

  const handleDamagePicturesChanged = () => {
    if (activeDamage) {
      changeLastDamagePicUpdated(activeDamage);
    }

    fetchEventDamages?.();
    fetchPictures?.();
  };

  const handleInspectionPointPicturesChanged = () => {
    fetchEventInspectionPoints?.();
    fetchPictures?.();
  };

  const getAndSetEventDetail = async () => {
    const queryResult = await getEventDetailApi();
    if (!queryResult.data?.event) {
      setEventNotFound(true);
    }

    const newEvent = {...queryResult.data?.event} as Partial<Event>;
    setActiveEvent(newEvent);
  };

  const handleShowWorkflow = () => {
    changeWorkflowModalDisplay(true);
  };

  const generateWorkpackHandler = (): Promise<boolean> => {
    startLoading();
    return generateWorkpackById({variables: {id: activeEvent!.id!}}).then(res => {
      if (res.data?.generateWorkpack === 0) {
        stopLoading();
        localStorage.setItem('workpackRegenerated', '1');
        navigate(0);
      } else {
        let message = t('message.error.workpackGenerationFailed');
        if (i18n.exists(`message.error.generateWorkpack.${res.data?.generateWorkpack}`)) {
          message = t(`message.error.generateWorkpack.${res.data?.generateWorkpack}`);
        }

        throw new Error(message, {
          cause: 'generate_workpack_failed',
        });
      }

      return true;
    }).catch((err: Error) => {
      let errorMessage: string = t('message.error.default.title');
      if (err.cause === 'generate_workpack_failed') {
        errorMessage = err.message;
      }

      AppNotifications.error(errorMessage);
      return false;
    });
  };

  const onGenerateWorkpackClick = async () => {
    if (activeEvent?.status === EWORKORDER_EVENT_STATUS.PREP_REVIEWING || activeEvent?.status === EWORKORDER_EVENT_STATUS.PREP_APPROVING) {
      setRegenerateConfirmModalOpened(true);
      return;
    }

    await generateWorkpackHandler();
  };

  const additionalActions: TAdditionalActions = {
    ...(prms.events.prepare && activeEvent?.status && [EWORKORDER_EVENT_STATUS.PREP_REVIEWING.toString(), EWORKORDER_EVENT_STATUS.PREP_APPROVING.toString()].includes(activeEvent.status)
      ? {[EAdditionalAction.GENERATE_WORKPACK]: <RadDropdownMenuItem className='gap-2' onClick={onGenerateWorkpackClick}><MdOutlineRefresh size={16}/> {t('label.regenerateWorkpack')}</RadDropdownMenuItem>} : {}),
    [EAdditionalAction.DISPLAY_WORKFLOW]: <RadDropdownMenuItem className='gap-2' onClick={handleShowWorkflow}><LuGitFork/> {t('label.workflow')}</RadDropdownMenuItem>,
    [EAdditionalAction.GENERATE_REPORT]: <RadDropdownMenuItem disabled={isReportGenerating} className='gap-2' onClick={launchReportGeneration}><LuRefreshCw className={`text-sm${isReportGenerating ? ' animate-spin' : ''}`}/> {t('label.generateReport')}</RadDropdownMenuItem>,
    ...(hasReport ? {[EAdditionalAction.DOWNLOAD_REPORT]: <RadDropdownMenuItem disabled={isReportGenerating} className='gap-2' onClick={downloadReport}><LuDownload className='text-sm'/> {t('label.downloadReport')}</RadDropdownMenuItem>} : {}),
    [EAdditionalAction.DELETE]: <RadDropdownMenuItem className='gap-2 item-destructive' onClick={() => changeDeleteEventModalDisplay(true)}><LuTrash2/> {t('label.deleteEvent')}</RadDropdownMenuItem>,
  };

  // fetch Event Pictures
  const getEventPictures = () => {
    getPicturesApi({
      variables: {
        evtId: activeEvent?.id!,
        orderBy: [
          {id: 'desc'},
        ],
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      setPictures((queryResult.data?.pictures ?? []) as Partial<Picture>[]);
    });
  };

  // fetch Event Inpsection Drawings
  const getEventInspectionDrawings = () => {
    getEventInspectionDrawingsApi({
      variables: {
        evtId: activeEvent?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      setEventInspectionDrawings((queryResult.data?.eventInspectionDrawings ?? []) as Partial<EventInspectionDrawing>[]);
    });
  };

  // fetch Event Cml
  const getEventCmls = () => {
    getEventCmlsApi({
      variables: {
        evtId: activeEvent?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      setEventCmls(_.sortBy((queryResult.data?.eventCmls ?? []) as Partial<EventCml>[], [evtCml => (evtCml.cml as Cml).position]));
    });
  };

  // fetch Event Damages
  const getEventDamages = () => {
    getEventDamagesApi({
      variables: {
        evtId: activeEvent?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      setEventDamages(_.sortBy((queryResult.data?.eventDamages ?? []) as Partial<EventDamage>[], [evtDmg => evtDmg.damage?.position]));
    });
  };

  const getEventFlocs = () => {
    getEventFlocsApi({
      variables: {
        evtId: activeEvent?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResults => {
      setEventFlocs((queryResults.data?.eventFlocs ?? []) as Partial<EventFloc>[]);
    });
  };

  const getEventTasks = () => {
    getEventTasksApi({
      variables: {
        evtId: activeEvent?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResults => {
      setEventTasks(_.sortBy((queryResults.data?.eventTasks ?? []) as Partial<EventTask>[], [(task: Partial<EventTask>) => task.task!.category, 'sort'], ['desc', 'asc']) as Partial<EventTask>[]);
    });
  };

  const getEventInspectionPoints = () => {
    getEventInspectionPointsApi({
      variables: {
        evtId: activeEvent?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      setEventInspectionPoints(_.sortBy(queryResult.data?.eventInspectionPoints as Partial<EventInspectionPoint>[], [evtPoint => evtPoint.inspectionPoint?.position]));
    });
  };

  // const handleLeftPanelResize = () => {
  //   if (!rightPanelRef.current || !containerRef.current) {
  //     return;
  //   }

  //   const prctWidth = (rightPanelRef.current as ImperativePanelHandle).getSize();
  //   const rightPanelWidth = containerRef.current.clientWidth * prctWidth / 100;
  //   setRightSideWidth(rightPanelWidth);
  // };

  const onValidateAssignWorkpack = (user: UserInfo) => {
    startLoading();
    const previousEventStatus = activeEvent!.status;
    const isReassign = previousEventStatus === EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION && activeEvent!.inspectorId;
    updateEventByIdApi({
      variables: {
        id: activeEvent!.id!,
        data: {
          inspectorId: {
            set: user.username,
          },
          status: {
            set: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
          },
        },
      },
    }).then(() => {
      setAssignModalOpened(false);
      getAndSetEventDetail();
      setActionConfirmModalOpened(true, isReassign ? 'reassign_workpack' : 'assign_workpack', (_, comment) => {
        createEventWorkflowFunc?.({
          variables: {
            data: {
              userLogin: user?.username,
              actionDate: new Date(),
              status: `${previousEventStatus}|${EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION}`,
              wrkoId: activeEvent!.id!,
              description: t(`label.eventActions.descriptions.${isReassign ? 'reassign_workpack' : 'assign_workpack'}`, {user: user.username}),
              comment,
            },
          },
        });
        AppNotifications.success(t('message.success.eventAssigned'));
        // Close event
        setActiveEvent();
        navigateOneSegmentBelow(location, navigate);
      }, {user: user.username});
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const onLeftSideResize = (size: number, _prevSize: number | undefined, containerRef: React.RefObject<HTMLDivElement>) => {
    setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 250) * (100 - size) / 100);
  };

  useEffect(() => {
    // Process Event datas Validation
    resetActiveEventValidation?.();

    if (!activeEvent
      || !eventInspectionPoints
      || !eventCmls
      || !eventDamages
      || !eventTasks
      || !eventInspectionDrawings) {
      return;
    }

    if (activeEvent?.status?.startsWith('PREP_') || activeEvent?.status?.startsWith('EXEC_')) {
      checkActiveEventPreparationValidity?.(t);
    } else if (activeEvent?.status?.startsWith('REP_')) {
      checkActiveEventReportingValidity?.(t);
    }
  }, [activeEvent, eventInspectionPoints, eventCmls, eventDamages, eventTasks, eventInspectionDrawings, eventDocuments, eventNotifications]);

  useEffect(() => {
    // Load event detail and se it as the active event.
    getAndSetEventDetail();
    // Workpack regenerated?
    if (localStorage.getItem('workpackRegenerated')) {
      AppNotifications.success(t('message.success.workpackRegenerated'));
      localStorage.removeItem('workpackRegenerated');
    }

    return () => {
      resetData(); // When unmounting component, reset event store datas.
    };
  }, []);

  useEffect(() => {
    console.log('event', activeEvent);
    if (activeEvent?.id) {
      setCreateEventWorkflowFunc(createEventWorkflowApi);
      setFetchPictures(getEventPictures);
      setFetchEventInspectionDrawings(getEventInspectionDrawings);
      setFetchEventCmls(getEventCmls);
      setFetchEventDamages(getEventDamages);
      setFetchEventFlocs(getEventFlocs);
      setFetchEventTasks(getEventTasks);
      setFetchEventInspectionPoints(getEventInspectionPoints);
      setFetchEventDocuments(getEventDocuments);
    }
  }, [activeEvent?.id]);

  useEffect(() => {
    setPointIdwgIds(_.uniq(_.concat(eventCmls?.map(item => item.cml?.idwgId) ?? [], eventDamages?.map(item => item.damage?.idwgId) ?? []).filter(id => !!id)) as number[]);
  }, [eventCmls, eventDamages]);

  useEffect(() => {
    setPointFlocIds(_.uniq(_.concat(eventCmls?.map(item => item.cml?.flocId) ?? [], eventDamages?.map(item => item.damage?.flocId) ?? [], eventInspectionPoints?.map(item => item.inspectionPoint?.flocId) ?? []).filter(id => !!id) ?? []) as number[]);
  }, [eventCmls, eventDamages, eventInspectionPoints]);

  useEffect(() => {
    fetchEventInspectionDrawings?.();
  }, [fetchEventInspectionDrawings]);

  // useEffect(() => {
  //   // Force right panel size calculation.
  //   handleLeftPanelResize();
  // }, [rightPanelRef.current, containerRef.current]);

  const itemsSummary: TSummaryItem[] = [
    {
      label: 'object',
      value: eventFlocs?.length ?? activeEvent?._count?.eventFlocs ?? 0,
    },
    {
      label: 'task',
      value: eventTasks?.length ?? activeEvent?._count?.eventTasks ?? 0,
    },
    {
      label: 'document',
      value: eventDocuments?.length ?? activeEvent?._count?.eventDocuments ?? 0,
    },
    {
      label: 'drawing',
      value: eventInspectionDrawings?.length ?? activeEvent?._count?.eventInspectionDrawings ?? 0,
    },
    {
      label: 'checklist',
      value: eventInspectionPoints?.length ?? activeEvent?._count?.eventInspectionPoints ?? 0,
    },
    {
      label: 'cml',
      value: eventCmls?.length ?? activeEvent?._count?.eventCmls ?? 0,
    },
    {
      label: 'damage',
      value: eventDamages?.length ?? activeEvent?._count?.eventDamages ?? 0,
    },
  ];

  useEffect(() => {
    if (eventFlocs && activeEvent && activeEvent?.schedulingTagId && !eventFlocs.find(eventFloc => eventFloc.flocId === activeEvent.schedulingTagId)) {
      createEventFlocApi({
        variables: {
          data: {
            flocId: activeEvent.schedulingTagId,
            wrkoId: activeEvent.id!,
            sort: 0,
          },
        },
      }).then(() => {
        setActiveEvent({
          ...activeEvent!,
          _count: {
            ...activeEvent._count!,
            eventFlocs: (activeEvent!._count?.eventFlocs ?? 0) + 1,
          },
        });
      });
    }
  }, [eventFlocs]);

  if (eventNotFound) {
    return <Error404/>;
  }

  if (!activeEvent) {
    return null;
  }

  return (
    <>
      <PageContent
        header={
          <DetailsPageTitleBar
            title={`${t('label.event')} ${activeEvent?.event}`}
            subtitle={`${activeEvent?.description as string | undefined} | ${activeEvent?.schedulingTag?.floc}`}
            actionButtons={{
              additionalActions,
            }}
          >
            <ReportGeneratingMesaggeWrapper isReportGenerating={isReportGenerating}>
              <ItemsSummary items={itemsSummary}/>
            </ReportGeneratingMesaggeWrapper>
          </DetailsPageTitleBar>
        }
        footer={activeEvent && <Footer event={activeEvent} readonly={userIsReadonly} validationErrors={eventValidationErrors} validationWarnings={eventValidationWarnings}/>}
      >
        {
          activeEvent
          && <MultipleResizablePanels
            panelsDefaultSize={[40, 60]}
            panelsMinSize={[30, 40]}
            autoSaveId='event-detail'
            resizerClassName='left-0 panelResizer'
            panelsOnResize={[onLeftSideResize, undefined]}
          // onRender={(containerRef: React.RefObject<HTMLDivElement>) => {
          //   setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 250) * 60 / 100);
          // }}
          >
            <LeftPanel readonly={userIsReadonly}/>
            <RightPanel readonly={userIsReadonly}/>
          </MultipleResizablePanels>
          // <RadResizablePanelGroup
          //   className='flex'
          //   direction='horizontal'
          //   autoSaveId='resizable-panel-event-details'>
          //   <RadResizablePanel defaultSize={50} minSize={33}>
          //     <LeftPanel readonly={userIsReadonly}/>
          //   </RadResizablePanel>
          //   <RadResizableHandle/>
          //   <RadResizablePanel ref={rightPanelRef} defaultSize={50} minSize={44} onResize={handleLeftPanelResize}>
          //     <RightPanel readonly={userIsReadonly}/>
          //   </RadResizablePanel>
          // </RadResizablePanelGroup>
        }

      </PageContent>
      <DeleteEventModal open={deleteEventModalOpen}/>
      {activeCml && activeCml.id !== OBJ_NEW_ID && <CmlModal isOpen item={activeCml!} event={activeEvent} eventCml={eventCmls?.find(item => item.cmlId === activeCml.id)} onCreatedOrUpdated={handleCmlCreatedOrUpdated} onClose={handleCmlModalClose} onPicturesChanged={handleCmlPicturesChanged}/>}
      {activeCml?.id === OBJ_NEW_ID && <CmlCreateModal isOpen item={activeCml} event={activeEvent} onCreated={cml => handleCmlCreatedOrUpdated(cml, EApiOperator.CREATE)} onOpenChange={isOpen => !isOpen && handleCmlModalClose()}/>}
      {activeDamage && activeDamage.id !== OBJ_NEW_ID && <DamageModal isOpen item={activeDamage!} eventDamage={eventDamages?.find(item => item.dmgeId === activeDamage!.id)} event={activeEvent} onCreatedOrUpdated={handleDamageCreatedOrUpdated} onClose={handleDamageModalClose} onPicturesChanged={handleDamagePicturesChanged}/>}
      {activeDamage?.id === OBJ_NEW_ID && <DamageCreateModal isOpen item={activeDamage} onCreated={dmg => handleDamageCreatedOrUpdated(dmg, EApiOperator.CREATE)} onOpenChange={isOpen => !isOpen && handleDamageModalClose()}/>}
      {!!activeInspectionPoint && activeInspectionPoint.id !== OBJ_NEW_ID && <InspectionPointModal isOpen event={activeEvent} eventInspectionPoint={eventInspectionPoints?.find(item => item.inspId === activeInspectionPoint.id)} item={activeInspectionPoint} onCreatedOrUpdated={handleInspectionPointCreatedOrUpdated} onClose={handleInspectionPointModalClose} onPicturesChanged={handleInspectionPointPicturesChanged}/>}
      {eventWorkflowModalDisplayed && <EventWorkflowTableModal open event={activeEvent!}/>}
      {!!activeNotification && activeNotification.id === OBJ_NEW_ID && <NotificationNewModal isOpen onCreated={handleNotificationCreatedOrUpdated} onOpenChange={isOpen => !isOpen && handleNotificationModalClose()}/>}
      {cmlSelectionModalDisplayed && <CmlSelectionModal/>}
      {damageSelectionModalDisplayed && <EventDamageSelectionModal/>}
      {documentSelectionModalDisplayed && <EventDocumentSelectionModal/>}
      {assignModalOpened && <UserAssignModal opened onClose={() => setAssignModalOpened(false)} onValidate={onValidateAssignWorkpack}/>}
      {idwgSelectionModalDisplayed && <EventInspectionDrawingSelectionModal open/>}
      {actionConfirmModalOpened && actionConfirmModalActionKey && <EventActionConfirmModal
        params={actionConfirmModalParams}
        actionKey={actionConfirmModalActionKey} onClose={(reason, comment) => {
          if (reason) {
            actionConfirmModalCallback?.(reason === 'cancel', comment);
          }

          setActionConfirmModalOpened(false);
        }}/>}
      {regenerateConfirmModalOpened
        && <ConfirmModal
          open
          destructiveAction
          title={t('label.regenerateWorkpack')}
          description={t('message.warning.regenerateWorkpackDataLoss')}
          onConfirm={generateWorkpackHandler}
          onClose={() => setRegenerateConfirmModalOpened(false)}/>}
    </>
  );
}
